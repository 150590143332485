import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, CircularProgress, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useInvitationUtilisateurParefeuMutation } from '../../state/api';
import { useTranslation } from 'react-i18next';

const InvitationParefeu = () => {
  const { token } = useParams();
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('');
  const userSession = useSelector((state) => state.userAuth.user);
  const [inviterUtilisateur] = useInvitationUtilisateurParefeuMutation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const handleInvitation = async () => {
      if (!userSession?.email) {
        setStatus('error');
        setMessage(t('errors.mustBeLoggedIn'));
        return;
      }

      try {
        const response = await inviterUtilisateur({
          token,
          email_utilisateur: userSession.email
        }).unwrap();

        setStatus('success');
        if (response.status === 'already_exists') {
          setMessage(t('success.alreadyHaveAccess'));
        } else if (response.status === 'token_expired') {
          setMessage(t('errors.invitationExpired'));
        } else if (response.status === 'token_invalid') {
          setMessage(t('errors.invalidInvitation'));
        } else {
          setMessage(t('success.invitationAccepted'));
        }
        
        setTimeout(() => {
          navigate('/mes-parefeux');
        }, 2000);
      } catch (error) {
        setStatus('error');
        if (error.data?.code === 'ALREADY_MEMBER') {
          setMessage(t('errors.alreadyMember'));
        } else if (error.data?.code === 'INVALID_TOKEN') {
          setMessage(t('errors.invalidToken'));
        } else if (error.data?.code === 'EXPIRED_TOKEN') {
          setMessage(t('errors.expiredToken'));
        } else {
          setMessage(error.data?.message || t('errors.invitationProcessing'));
        }
      }
    };

    handleInvitation();
  }, [token, userSession, inviterUtilisateur, navigate, t]);

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="80vh"
      bgcolor={theme.palette.background.default}
    >
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4, 
          maxWidth: 500,
          bgcolor: theme.palette.background.alt,
          color: theme.palette.text.primary
        }}
      >
        <Typography 
          variant="h4" 
          gutterBottom 
          align="center"
          color={theme.palette.text.primary}
        >
          {t('invitation.invitationTitle')}
        </Typography>

        {status === 'loading' && (
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <CircularProgress />
            <Typography color={theme.palette.text.primary}>
              {t('invitation.processingInvitation')}
            </Typography>
            <Typography 
              variant="body2" 
              color={theme.palette.text.secondary}
            >
              {t('invitation.configuringAccess')}
            </Typography>
          </Box>
        )}
        
        {status === 'success' && (
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Typography 
              variant="h6" 
              color={theme.palette.primary.main}
            >
              {message}
            </Typography>
            <Typography color={theme.palette.text.primary}>
              {t('invitation.redirectingToDashboard')}
            </Typography>
          </Box>
        )}

        {status === 'error' && (
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Typography 
              variant="h6" 
              color={theme.palette.secondary.main}
            >
              {message}
            </Typography>
            <Typography color={theme.palette.text.primary}>
              {t('invitation.contactAdminIfPersists')}
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default InvitationParefeu;