import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import styled from '@emotion/styled';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAllInfoNoeudMutation, useGetAllInfoSectorMutation, usePublicGetInfoRegleSpecialMutation } from 'state/api';
import WebFont from 'webfontloader';
import { useTranslation } from 'react-i18next';
import NoeudSelector from './NoeudSelector';
import LogoDisplay from '../LogoDisplay';

// Utilisation des variables d'environnement pour l'URL de base
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const StyledPage = styled('div')`
  background-color: ${(props) => props.couleur_de_fond_haut || '#FFFFFF'} !important;
  color: ${(props) => props.couleur_ecriture || '#000000'} !important;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  overflow-x: hidden !important;
  position: relative;

  @media (max-width: 767px) {
    height: auto;
    min-height: 100vh;
  }
`;

const TopSection = styled('div')`
  position: relative;
  padding: 2rem;
  background-color: transparent;
  z-index: 3;
  overflow: hidden;
`;

const BannerImage = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

const BottomSection = styled('div')`
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  position: relative;
  z-index: 2;

  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const ServiceCard = styled('div')`
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  color: ${(props) => props.couleur_ecriture || '#000000'};
  box-shadow: 5px 5px 10px ${(props) => props.couleur_dombre_de_case || 'rgba(0, 0, 0, 0.1)'};
  border-radius: 10px;
  padding: 1rem;
  width: 450px;
  height: 120px;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative;
  z-index: 3;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 767px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const ServiceImage = styled('img')`
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 1rem;

  @media (max-width: 767px) {
    width: 60px;
    height: 60px;
  }
`;

const ServiceContent = styled('div')`
  flex: 1;
`;

const ServiceTitle = styled(Typography)`
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const ServiceDescription = styled(Typography)`
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  font-size: 1rem;
  opacity: 0.8;
`;

const HeaderContainer = styled('div')`
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2rem;
`;

const Title = styled(Typography)`
  color: ${(props) => props.couleur_ecriture_banniere || '#FFFFFF'} !important;
  font-family: ${(props) => props.police_de_caractere} !important;
  font-size: 2.5vw !important;
  margin-top: 10px !important;
  
  @media (max-width: 767px) {
    font-size: 6vw !important;
  }
`;

const Explanation = styled(Typography)`
  color: ${(props) => props.couleur_ecriture_banniere || '#FFFFFF'} !important;
  font-family: ${(props) => props.police_de_caractere} !important;
  font-size: 1.25vw !important;
  
  @media (max-width: 767px) {
    font-size: 4vw !important;
  }
`;

const AccessDeniedMessage = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffffff;

  .access-denied-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ff0000;
    padding: 20px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 2rem;
    position: relative;

    .icon {
      font-size: 4rem;
      margin-bottom: 16px;
    }

    .home-button {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
`;

const ServiceRow = styled('div')`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LogoContainer = styled('div')`
  width: 40%;
  margin-right: 60px;
  margin-left: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
    justify-content: center;
  }
`;

const TitleContainer = styled('div')`
  width: 60%;
  text-align: left;
  
  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
  }
`;

const FormulaireFrontAcceuil = () => {
  const { ID_Noeud, ID_Etablissement } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userSession = useSelector((state) => state.userAuth);
  const [getInfoWithNoeudCheck] = useAllInfoNoeudMutation();
  const [getInfoSector] = useGetAllInfoSectorMutation();
  const [getInfoRegleSpecial] = usePublicGetInfoRegleSpecialMutation();
  const [personnalisation, setPersonnalisation] = useState({});
  const [noeuds, setNoeuds] = useState([]);
  const [isNoeudOrigine, setIsNoeudOrigine] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [accessDenied, setAccessDenied] = useState(false);
  const redirecting = useRef(false);

  const getImageUrl = (path) => {
    if (!path) return null;
    
    // Si l'URL est complète (commence par http ou https), extraire le chemin après "images/"
    if (path.startsWith('http')) {
      const match = path.match(/images\/(.*)/);
      if (match) {
        return `${BASE_URL}/images/displayimagewithpath/${encodeURIComponent(match[1])}`;
      }
      return path;
    }
    
    // Sinon, traiter comme avant
    const imagePath = path.split('/').pop();
    return `${BASE_URL}/images/displayimagewithpath/${encodeURIComponent(imagePath)}`;
  };

  const getBannerUrl = (path) => {
    if (!path) return null;
    
    if (path.startsWith('http')) {
      const match = path.match(/images\/(.*)/);
      if (match) {
        return `${BASE_URL}/images/displayimagewithpath/${encodeURIComponent(match[1])}`;
      }
      return path;
    }
    
    const imagePath = path.split('/').pop();
    return `${BASE_URL}/images/displayimagewithpath/${encodeURIComponent(imagePath)}`;
  };

  useEffect(() => {
    const checkRegleSpecial = async () => {
      try {
        const regleSpecial = await getInfoRegleSpecial({ ID_Etablissement }).unwrap();
        
        if (regleSpecial.ConnexionPrive) {
          if (!userSession.isAuthenticated || !userSession.user || !userSession.user.email) {
            const redirectPath = encodeURIComponent(window.location.pathname);
            if (!redirecting.current) {
              redirecting.current = true;
              navigate(`/loginclient?redirectTo=${redirectPath}`);
            }
            return;
          }

          const userDomain = userSession.user.email.split('@').pop();
          const isDomainAllowed = regleSpecial.DomainePrive.some(domain => userDomain.endsWith(domain.trim()));

          if (!isDomainAllowed) {
            setAccessDenied(true);
            return;
          }
        }
        
        fetchInfo();
      } catch (error) {
        console.error('Erreur lors de la vérification des règles spéciales:', error);
        setErrorMessage(t('FormulaireFrontAcceuil.error_rule_check'));
      }
    };

    const fetchInfo = async () => {
      if (ID_Noeud && ID_Etablissement) {
        try {
          const data = await getInfoWithNoeudCheck({ ID_Noeud, ID_Etablissement }).unwrap();
          setPersonnalisation(data.personnalisation || {});
          setIsNoeudOrigine(data.isNoeudOrigine);

          if (data.personnalisation && data.personnalisation.URL_police_de_caractere) {
            WebFont.load({
              google: {
                families: [data.personnalisation.URL_police_de_caractere]
              }
            });
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des données:', error);
        }

        try {
          const sectorData = await getInfoSector({ ID_Etablissement }).unwrap();
          setNoeuds(sectorData[0].children || []);
        } catch (error) {
          console.error('Erreur lors de la récupération des données de secteur:', error);
        }
      }
    };

    checkRegleSpecial();
  }, [ID_Noeud, ID_Etablissement, getInfoWithNoeudCheck, getInfoSector, getInfoRegleSpecial, userSession, navigate, t]);

  const handleCategoryClick = (category) => {
    if (category === 'signalement') {
      navigate(`/client/signalement/${ID_Noeud}/${ID_Etablissement}`);
    } else if (category === 'services') {
      navigate(`/client/services/${ID_Etablissement}`);
    } else if (category === 'feedback') {
      navigate(`/client/feedback/${ID_Noeud}/${ID_Etablissement}`);
    }
  };

  const handleHomeClick = () => {
    navigate('/infoutilisateur');
  };

  const categories = [
    { id: 'signalement', name: t('FormulaireFrontAcceuil.signalement'), image: 'client\\attention.png' },
    { id: 'services', name: t('FormulaireFrontAcceuil.services'), image: 'client\\service-clients.png' },
    { id: 'feedback', name: t('FormulaireFrontAcceuil.feedback'), image: 'client\\feedback.png' },
  ];

  if (accessDenied) {
    return (
      <AccessDeniedMessage>
        <Button
          variant="contained"
          color="primary"
          startIcon={<HomeIcon />}
          onClick={handleHomeClick}
          className="home-button"
          sx={{ position: 'fixed', top: 10, left: 10 }}
        >
          {t('FormulaireFrontAcceuil.home')}
        </Button>
        <div className="access-denied-box">
          <div className="icon">⚠️</div>
          {t('FormulaireFrontAcceuil.access_denied')}
        </div>
      </AccessDeniedMessage>
    );
  }

  return (
    <StyledPage
      couleur_de_fond_haut={personnalisation.couleur_de_fond_haut}
      couleur_ecriture={personnalisation.couleur_ecriture}
      police_de_caractere={personnalisation.police_de_caractere}
    >
      <TopSection>
        {personnalisation.Banniere && getBannerUrl(personnalisation.Banniere) && (
          <BannerImage
            src={getBannerUrl(personnalisation.Banniere)}
            alt="Bannière"
            onError={(e) => {
              e.target.style.display = 'none';
              console.error('Erreur de chargement de la bannière');
            }}
          />
        )}
        <HeaderContainer>
          <LogoContainer>
            <LogoDisplay 
              logo={personnalisation.Logo}
              baseUrl={BASE_URL}
              height="120px"
              width="auto"
            />
          </LogoContainer>
          <TitleContainer>
            <Title 
              couleur_ecriture_banniere={personnalisation.couleur_ecriture_banniere} 
              police_de_caractere={personnalisation.police_de_caractere}
            >
              {t('FormulaireFrontAcceuil.title')}
            </Title>
            <Explanation 
              couleur_ecriture_banniere={personnalisation.couleur_ecriture_banniere} 
              police_de_caractere={personnalisation.police_de_caractere}
            >
              {t('FormulaireFrontAcceuil.explanation')}
            </Explanation>
          </TitleContainer>
        </HeaderContainer>
        <div style={{ position: 'relative', zIndex: 10 }}>
          <NoeudSelector styleProps={personnalisation} noeuds={noeuds} ID_Etablissement={ID_Etablissement} />
        </div>
      </TopSection>

      <BottomSection>
        <ServiceRow>
          <ServiceCard
            onClick={() => handleCategoryClick(categories[0].id)}
            couleur_ecriture={personnalisation.couleur_ecriture}
            couleur_dombre_de_case={personnalisation.couleur_dombre_de_case}
          >
            <ServiceImage src={getImageUrl(categories[0].image)} alt={categories[0].name} />
            <ServiceContent>
              <ServiceTitle police_de_caractere={personnalisation.police_de_caractere}>
                {categories[0].name}
              </ServiceTitle>
              <ServiceDescription police_de_caractere={personnalisation.police_de_caractere}>
                {t(`FormulaireFrontAcceuil.${categories[0].id}_description`)}
              </ServiceDescription>
            </ServiceContent>
          </ServiceCard>
          
          <ServiceCard
            onClick={() => handleCategoryClick(categories[1].id)}
            couleur_ecriture={personnalisation.couleur_ecriture}
            couleur_dombre_de_case={personnalisation.couleur_dombre_de_case}
          >
            <ServiceImage src={getImageUrl(categories[1].image)} alt={categories[1].name} />
            <ServiceContent>
              <ServiceTitle police_de_caractere={personnalisation.police_de_caractere}>
                {categories[1].name}
              </ServiceTitle>
              <ServiceDescription police_de_caractere={personnalisation.police_de_caractere}>
                {t(`FormulaireFrontAcceuil.${categories[1].id}_description`)}
              </ServiceDescription>
            </ServiceContent>
          </ServiceCard>
        </ServiceRow>

        <ServiceRow>
          <ServiceCard
            onClick={() => handleCategoryClick(categories[2].id)}
            couleur_ecriture={personnalisation.couleur_ecriture}
            couleur_dombre_de_case={personnalisation.couleur_dombre_de_case}
          >
            <ServiceImage src={getImageUrl(categories[2].image)} alt={categories[2].name} />
            <ServiceContent>
              <ServiceTitle police_de_caractere={personnalisation.police_de_caractere}>
                {categories[2].name}
              </ServiceTitle>
              <ServiceDescription police_de_caractere={personnalisation.police_de_caractere}>
                {t(`FormulaireFrontAcceuil.${categories[2].id}_description`)}
              </ServiceDescription>
            </ServiceContent>
          </ServiceCard>
        </ServiceRow>
      </BottomSection>
    </StyledPage>
  );
};

export default FormulaireFrontAcceuil;
