import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button, TextField, Dialog, DialogTitle,
  DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox,
  Alert, List, ListItem, ListItemText, IconButton, TablePagination,
  Select, MenuItem, FormControl, InputLabel, FormHelperText, Grid,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  useGetAllInfoServiceITMutation,
  useAddServiceITMutation,
  useModifyServiceITMutation,
  useDeleteServiceITMutation,
  useGetGroupedAdminsMutation,
  useGetAllTypeDactionITMutation,
} from 'state/api';

import ErrorMessage from '../ErrorMessage';
import ValidationMessage from '../ValidationMessage';
import InfoMessage from '../InfoMessage';

// Utilisation des variables d'environnement pour l'URL de base
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const RenderServiceIT = () => {
  const { t } = useTranslation();
  const [services, setServices] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [editingService, setEditingService] = useState(null);

  const [titre, setTitre] = useState('');
  const [description, setDescription] = useState('');
  const [besoin, setBesoin] = useState('');
  const [precisionBesoin, setPrecisionBesoin] = useState('');
  const [dateRequise, setDateRequise] = useState('Non');
  const [lieuRequis, setLieuRequis] = useState(false);
  const [fichierRequis, setFichierRequis] = useState(false);
  const [logo, setLogo] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedGroupe, setSelectedGroupe] = useState('');
  const [selectedTypeDaction, setSelectedTypeDaction] = useState('');

  const [errors, setErrors] = useState({ titre: false, description: false, logo: false, selectedCategoryId: false });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [nomServiceRecherche, setNomServiceRecherche] = useState('');

  const [groupes, setGroupes] = useState([]);
  const [typesDaction, setTypesDaction] = useState([]);

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState('');

  const popUpError = (message) => {
    setError(message);
  };

  const popUpInfo = (message) => {
    setMessage(message);
  };

  const popUpValidation = (message) => {
    setValidation(message);
  };

  // API hooks
  const [getAllServices] = useGetAllInfoServiceITMutation();
  const [addService] = useAddServiceITMutation();
  const [modifyService] = useModifyServiceITMutation();
  const [deleteService] = useDeleteServiceITMutation();
  const [getGroupedAdmins] = useGetGroupedAdminsMutation();
  const [getAllTypeDactionIT] = useGetAllTypeDactionITMutation();

  const userSession = useSelector((state) => state.adminAuth);

  const indexOfFirstService = page * rowsPerPage;
  const indexOfLastService = indexOfFirstService + rowsPerPage;
  const currentServices = allServices.slice(indexOfFirstService, indexOfLastService);

  const fetchServices = async () => {
    const fetchedServices = await getAllServices({ ID_Etablissement: userSession.ID_Etablissement });
    if (fetchedServices.data) {
      setServices(fetchedServices.data);
      const flatServices = fetchedServices.data.reduce((acc, category) => acc.concat(category.services), []);
      setAllServices(flatServices);
    }
  };

  useEffect(() => {
    const fetchGroupes = async () => {
      const response = await getGroupedAdmins({ ID_Etablissement: userSession.ID_Etablissement });
      if (response.data) {
        setGroupes(response.data);
      }
    };

    const fetchTypesDaction = async () => {
      const response = await getAllTypeDactionIT({ ID_Etablissement: userSession.ID_Etablissement });
      if (response.data) {
        setTypesDaction(response.data);
      }
    };

    fetchServices();
    fetchGroupes();
    fetchTypesDaction();
  }, [getAllServices, getGroupedAdmins, getAllTypeDactionIT, userSession.ID_Etablissement]);

  useEffect(() => {
    if (editingService) {
      setTitre(editingService.Titre || '');
      setDescription(editingService.Description_Service || '');
      setLogo(editingService.Logo_Requis || null);
      setSelectedCategoryId(editingService.ID_Categorie_Service_IT || '');
      setSelectedGroupe(editingService.ID_Groupe || '');
      setSelectedTypeDaction(editingService.ID_Type_Daction_IT || '');
      setBesoin(editingService.Besoin || '');
      setPrecisionBesoin(editingService.Precision_Besoin || '');
      setDateRequise(editingService.Date_Requise || 'Non');
      setLieuRequis(editingService.Lieu_Requis || false);
      setFichierRequis(editingService.Fichier_Requis || false);
    }
  }, [editingService]);

  const validateAndSubmit = () => {
    let hasError = false;
    let newErrors = {
      titre: false,
      description: false,
      logo: false,
      selectedCategoryId: false,
    };

    if (!titre.trim()) {
      newErrors.titre = true;
      hasError = true;
    }
    if (!description.trim()) {
      newErrors.description = true;
      hasError = true;
    }
    if (!selectedCategoryId) {
      newErrors.selectedCategoryId = true;
      hasError = true;
    }
    if (!logo && !editingService) {
      newErrors.logo = true;
      hasError = true;
    }

    setErrors(newErrors);

    if (!hasError) {
      handleAddOrEditService();
    }
  };

  const handleOpenNewServiceDialog = () => {
    setEditingService(null);
    setTitre('');
    setDescription('');
    setBesoin('');
    setPrecisionBesoin('');
    setDateRequise('Non');
    setLieuRequis(false);
    setFichierRequis(false);
    setLogo(null);
    setSelectedCategoryId('');
    setSelectedGroupe('');
    setSelectedTypeDaction('');
    setErrors({ titre: false, description: false, logo: false, selectedCategoryId: false });
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setEditingService(null);
    setErrors(false);
  };

  const handleDeleteConfirmation = (service) => {
    setServiceToDelete(service);
    setConfirmationOpen(true);
  };

  const handleFileChange = (event) => {
    setLogo(event.target.files[0]);
    setErrors(false);
  };

  const handleAddOrEditService = async () => {
    const formData = new FormData();
    formData.append('ID_Categorie_Service_IT', selectedCategoryId);
    formData.append('ID_Groupe', selectedGroupe);
    formData.append('ID_Type_Daction_IT', selectedTypeDaction);
    formData.append('Titre', titre);
    formData.append('Description_Service', description);
    formData.append('Besoin', besoin);
    formData.append('Precision_Besoin', precisionBesoin);
    formData.append('Date_Requise', dateRequise);
    formData.append('Lieu_Requis', lieuRequis);
    formData.append('Fichier_Requis', fichierRequis);
    
    if (logo instanceof File) {
      formData.append('logo', logo);
    }

    if (editingService) {
      formData.append('ID_Service_IT', editingService.ID_Service_IT);
    }

    try {
      if (editingService) {
        await modifyService(formData).unwrap();
        popUpValidation(t('RenderServiceIT.validation_edit'));
      } else {
        await addService(formData).unwrap();
        popUpValidation(t('RenderServiceIT.validation_add'));
      }
      handleCloseDialog();
      await fetchServices();
    } catch (error) {
      popUpError(t('RenderServiceIT.error_server'));
    }
  };

  const handleEdit = async (service) => {
    setEditingService(service);
    setTitre(service.Titre || '');
    setDescription(service.Description_Service);
    setBesoin(service.Besoin || '');
    setPrecisionBesoin(service.Precision_Besoin || '');
    setDateRequise(service.Date_Requise || 'Non');
    setLieuRequis(service.Lieu_Requis || false);
    setFichierRequis(service.Fichier_Requis || false);
    setSelectedCategoryId(service.ID_Categorie_Service_IT || '');
    setSelectedGroupe(service.ID_Groupe || '');
    setSelectedTypeDaction(service.ID_Type_Daction_IT || '');
    setLogo(null);
    setErrors({ titre: false, description: false, logo: false, selectedCategoryId: false });
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteService({ ID_Service_IT: serviceToDelete.ID_Service_IT });
      setConfirmationOpen(false);
      setServiceToDelete(null);
      fetchServices();
      popUpValidation(t('RenderServiceIT.validation_delete'));
    } catch (error) {
      popUpError(t('RenderServiceIT.error_delete'));
    }
  };

  const handleSearchService = () => {
    if (nomServiceRecherche.trim()) {
      const filteredServices = services.reduce((acc, category) => {
        const matchedServices = category.services.filter(service =>
          service.Titre.toLowerCase().includes(nomServiceRecherche.toLowerCase())
        );
        return acc.concat(matchedServices);
      }, []);

      setAllServices(filteredServices);
    } else {
      const flatServices = services.reduce((acc, category) => acc.concat(category.services), []);
      setAllServices(flatServices);
    }
    setPage(0);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationOpen(false);
    setServiceToDelete(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t('RenderServiceIT.manage_services')}
      </Typography>

      <ErrorMessage message={error} setError={setError} />
      <InfoMessage message={message} setInfo={setMessage} />
      <ValidationMessage message={validation} setValidation={setValidation} />

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} style={{ textAlign: 'left' }}>
          <Button variant="contained" color="primary" onClick={handleOpenNewServiceDialog}>
            {t('RenderServiceIT.add_service')}
          </Button>
        </Grid>
        <Grid item xs={7} style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
          <TextField
            label={t('RenderServiceIT.service_name')}
            value={nomServiceRecherche}
            onChange={(e) => setNomServiceRecherche(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchService}
            style={{ marginLeft: '8px' }}
          >
            <SearchIcon style={{ fontSize: 40 }} />
          </Button>
        </Grid>
      </Grid>
      <List style={{ marginTop: '20px' }}>
        {currentServices.map((service) => {
          const typeDaction = typesDaction.find(type => type.ID_Type_Daction_IT === service.ID_Type_Daction_IT);
          const groupe = groupes.find(grp => grp.ID_Groupe === service.ID_Groupe);
          const color = typeDaction ? `#${typeDaction.Couleur.slice(2)}` : '#fff';

          return (
            <ListItem key={service._id} secondaryAction={
              <>
                <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(service)}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteConfirmation(service)}>
                  <DeleteIcon />
                </IconButton>
              </>
            }>
              <div style={{ width: '20px', height: '20px', backgroundColor: color, marginRight: '10px', borderRadius: '4px', boxShadow: '0 0 3px rgba(0, 0, 0, 0.3)' }}></div>
              <ListItemText
                primary={service.Titre}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                    >
                      {`${t('RenderServiceIT.category')}: ${service.ID_Categorie_Service_IT}, Groupe: ${groupe ? groupe.Nom_groupe : 'Groupe inconnu'}, Type d'action: ${typeDaction ? typeDaction.Titre : 'Type d\'action inconnu'}`}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          );
        })}
      </List>

      <TablePagination
        component="div"
        count={allServices.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{editingService ? t('RenderServiceIT.edit_service') : t('RenderServiceIT.add_new_service')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t('RenderServiceIT.service_title')}
            type="text"
            fullWidth
            value={titre}
            onChange={(e) => setTitre(e.target.value)}
            error={errors.titre}
            helperText={errors.titre ? t('RenderServiceIT.service_title_error') : ""}
          />
          <TextField
            margin="dense"
            label={t('RenderServiceIT.service_description')}
            type="text"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={errors.description}
            helperText={errors.description ? t('RenderServiceIT.service_description_error') : ""}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="date-requise-select-label">{t('RenderServiceIT.date_required')}</InputLabel>
            <Select
              labelId="date-requise-select-label"
              id="date-requise-select"
              value={dateRequise}
              label={t('RenderServiceIT.date_required')}
              onChange={(e) => setDateRequise(e.target.value)}
            >
              <MenuItem value="Non">{t('RenderServiceIT.no')}</MenuItem>
              <MenuItem value="Date_unique">{t('RenderServiceIT.single_date')}</MenuItem>
              <MenuItem value="Intervalle_de_date">{t('RenderServiceIT.date_range')}</MenuItem>
              <MenuItem value="Date_unique_avec_heure_unique">{t('RenderServiceIT.single_date_time')}</MenuItem>
              <MenuItem value="Date_unique_avec_intervalle_dheure">{t('RenderServiceIT.single_date_time_range')}</MenuItem>
              <MenuItem value="Intervalle_de_date_avec_heure_unique">{t('RenderServiceIT.date_range_single_time')}</MenuItem>
              <MenuItem value="Intervalle_de_date_avec_intervalle_dheure">{t('RenderServiceIT.date_range_time_range')}</MenuItem>
            </Select>
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={lieuRequis}
                  onChange={(e) => setLieuRequis(e.target.checked)}
                />
              }
              label={t('RenderServiceIT.location_required')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={fichierRequis}
                  onChange={(e) => setFichierRequis(e.target.checked)}
                />
              }
              label={t('RenderServiceIT.file_required')}
            />
          </FormGroup>
          <TextField
            margin="dense"
            label={t('RenderServiceIT.specific_needs')}
            type="text"
            fullWidth
            value={besoin}
            onChange={(e) => setBesoin(e.target.value)}
          />
          <TextField
            margin="dense"
            label={t('RenderServiceIT.need_precision')}
            type="text"
            fullWidth
            value={precisionBesoin}
            onChange={(e) => setPrecisionBesoin(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="service-select-label">{t('RenderServiceIT.category')}</InputLabel>
            <Select
              labelId="service-select-label"
              id="service-select"
              value={selectedCategoryId}
              label={t('RenderServiceIT.category')}
              onChange={e => setSelectedCategoryId(e.target.value)}
              displayEmpty
              style={{ marginBottom: '16px' }}
            >
              <MenuItem value="">
                <em>{t('RenderServiceIT.select_category')}</em>
              </MenuItem>
              {services.map((service) => (
                <MenuItem key={service.ID_Categorie_Service_IT} value={service.ID_Categorie_Service_IT}>
                  {service.Titre}
                </MenuItem>
              ))}
            </Select>
            {errors.selectedCategoryId && <FormHelperText>{t('RenderServiceIT.category_error')}</FormHelperText>}
          </FormControl>
          <Select
            value={selectedGroupe}
            onChange={(e) => setSelectedGroupe(e.target.value)}
            fullWidth
            margin="normal"
            displayEmpty
            style={{ marginBottom: '16px' }}
          >
            <MenuItem value="">
              <em>{t('RenderServiceIT.select_group')}</em>
            </MenuItem>
            {groupes.map(grp => (
              <MenuItem key={grp.ID_Groupe} value={grp.ID_Groupe}>
                {grp.Nom_groupe}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={selectedTypeDaction}
            onChange={(e) => setSelectedTypeDaction(e.target.value)}
            fullWidth
            margin="normal"
            displayEmpty
            style={{ marginBottom: '16px' }}
          >
            <MenuItem value="">
              <em>{t('RenderServiceIT.select_action_type')}</em>
            </MenuItem>
            {typesDaction.map(type => (
              <MenuItem key={type.ID_Type_Daction_IT} value={type.ID_Type_Daction_IT}>
                {type.Titre}
              </MenuItem>
            ))}
          </Select>
          {editingService && editingService.Logo_Requis && (
            <Box display="flex" flexDirection="column" alignItems="center" marginTop={2}>
              <Typography variant="subtitle1">{t('RenderServiceIT.current_logo')} :</Typography>
              <img
                src={`${BASE_URL}/service_it/image/${editingService.ID_Service_IT}?${Date.now()}`}
                alt="Service Logo"
                style={{ maxWidth: '250px', margin: '10px auto', borderRadius: '8px' }}
                onError={(e) => {
                  console.error('Erreur de chargement de l\'image');
                  e.target.src = '/default-service-image.png';
                }}
              />
            </Box>
          )}
          <Box marginTop={2}>
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
              style={{ display: 'block', width: '100%' }}
            />
          </Box>
          {errors.logo && <Alert severity="error">{t('RenderServiceIT.logo_required')}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t('RenderServiceIT.cancel')}</Button>
          <Button onClick={validateAndSubmit}>{editingService ? t('RenderServiceIT.save') : t('RenderServiceIT.submit')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationOpen} onClose={handleCloseConfirmationDialog}>
        <DialogTitle>{t('RenderServiceIT.delete_service')}</DialogTitle>
        <DialogContent>
          <Typography>{t('RenderServiceIT.confirm_delete')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog}>{t('RenderServiceIT.cancel')}</Button>
          <Button onClick={handleDelete} color="error">{t('RenderServiceIT.delete')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RenderServiceIT;
