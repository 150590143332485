import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

const LogoContainer = styled(Box)`
  img {
    height: ${props => props.height || '100%'};
    width: ${props => props.width || '200px'};
    min-height: ${props => props.minHeight || 'auto'};
    max-height: ${props => props.maxHeight || 'none'};
    min-width: ${props => props.minWidth || 'auto'};
    max-width: ${props => props.maxWidth || 'none'};
    object-fit: contain;
  }
`;

const LogoDisplay = ({ 
  logo,
  baseUrl,
  height,
  width,
  minHeight,
  maxHeight, 
  minWidth,
  maxWidth,
  isTemporary = false,
  temporaryImage = null
}) => {
  const getImageUrl = (path) => {
    if (!path) return null;
    
    if (path.startsWith('http')) {
      const match = path.match(/images\/(.*)/);
      if (match) {
        return `${baseUrl}/images/displayimagewithpath/${encodeURIComponent(match[1])}`;
      }
      return path;
    }
    
    const imagePath = path.split('/').pop();
    return `${baseUrl}/images/displayimagewithpath/${encodeURIComponent(imagePath)}`;
  };

  if (isTemporary && temporaryImage) {
    return (
      <LogoContainer 
        height={height} 
        width={width}
        minHeight={minHeight}
        maxHeight={maxHeight}
        minWidth={minWidth} 
        maxWidth={maxWidth}
      >
        <img
          src={URL.createObjectURL(temporaryImage)}
          alt="Logo établissement (temporaire)"
          onError={(e) => console.error('Erreur de chargement du logo temporaire:', e)}
        />
      </LogoContainer>
    );
  }

  return logo ? (
    <LogoContainer 
      height={height} 
      width={width}
      minHeight={minHeight}
      maxHeight={maxHeight}
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      <img
        src={getImageUrl(logo)}
        alt="Logo établissement"
        onError={(e) => console.error('Erreur de chargement du logo:', e)}
      />
    </LogoContainer>
  ) : null;
};

LogoDisplay.propTypes = {
  logo: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  isTemporary: PropTypes.bool,
  temporaryImage: PropTypes.object
};

export default LogoDisplay;