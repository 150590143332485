import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo, useContext , useEffect } from "react";
import { useSelector, useDispatch  } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Secteur from "scenes/secteur/index.jsx";
import SecteurIT from "scenes/SecteurIT/index.jsx";
import SecteurSG from "scenes/SecteurSG/index.jsx";
import Signalements from "scenes/signalement";
import IncidentIT from "scenes/incident_it/index.jsx";
import IncidentSG from "scenes/incident_sg/index.jsx";
import Overview from "scenes/overview";
import Admin from "scenes/admin";
import Personnalisation from "scenes/personnalisation";
import Login from "scenes/login";
import AdminLogin from "scenes/loginAdmin";
import AdminDashboard from "scenes/dashboardAdmin";
import PrivateRoute from './components/PrivateRoute.jsx';
import SubscriptionRoute from './components/SubscriptionRoutes.jsx'; // Importer SubscriptionRoute
import ClientS from "scenes/ClientS";
import ClientE from "scenes/ClientE";
import LoginUser from "scenes/loginuser";
import ListeEtablissement from "scenes/listeetablissement/index.jsx";
import AcceuilPublic from "scenes_public/pageDacceuil/index.jsx";
import Inscription from "scenes_public/inscription/index.jsx";
import VerifyEmail from "scenes_public/verifyemail/index.jsx";
import UserRoute from "components_public/userRoute.jsx";
import Services from "scenes_utilisateur/facturation/index.jsx";
import InformationsPersonnel from "scenes_utilisateur/informationPersonnel/index.jsx";
import InscriptionEtablissement from "scenes_public/inscriptionEtablissement/inde.jsx";
import RejoindreEtablissement from "scenes_public/rejoindreEtablissement/index.jsx";
import ServiceIT from "scenes/service_it/index.jsx";
import ServiceSG from "scenes/service_sg/index.jsx";
import SignalementsSG from "scenes/signalement_sg/index.jsx";
import FeedbackApp from "scenes/feedback/index.jsx";
import FormulaireFrontAcceuil from "components/frontclient/pagefrontacceuil.jsx";
import PagePrincipalServices from "components/frontclient/PagePrincipalServices.jsx";
import PagePrincipalFeedback from "components/frontclient/PagePrincipalFeedback.jsx";
import PagePrincipalSignalement from "components/frontclient/PagePrincipalSignalements.jsx";
import EquipementParticulierForm from "components/frontclient/EquipementParticulierSolo.jsx";
import LoginClient from "components/frontclient/LoginClient.jsx";
import InscriptionClient from "components/frontclient/InscriptionClient.jsx";
import PortailIT from "scenes/portail_it/index.jsx";
import PortailSG from "scenes/portail_sg/index.jsx";
import SouscriptionEtablissement from "scenes_public/souscription_etablissement_as/index.jsx";
import VerifyPortail from "scenes/verifyportail/index.jsx";
import PagePortailAdmin from "scenes/PortailAdmin/index.jsx";
import Partenaires from "scenes/Partenaire/index.jsx";
import Statistique from "scenes/Statistique/index.jsx";
import LoginUserPerEtablissement from "scenes/loginUserPerEtablissment/index.jsx";
import Abonnements from "scenes_public/abonnements/index.jsx";
import Documentation from "scenes_public/documentation/index.jsx";
import AbonnementsWithoutBackground from "scenes_utilisateur/abonnement/index.jsx";
import Developer from "scenes/developer/index.jsx";
import SubscriptionResult from "scenes/subscriptionresult/index.jsx";
import PrivacyPolitic from "scenes_public/privacypolitic/index.jsx";
import DocDeveloper from "scenes_public/documentation/index.jsx";
import APropos from "scenes_public/A_propos/index.jsx";
import ListeTickets from "scenes/listetickets/index.jsx";
import { useLanguage } from './hooks/useLanguage';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from './contexts/LanguageContext';
import { LanguageSelector } from './components/LanguageSelector';
import Parefeu from "scenes/parefeu/index.jsx";
import InvitationParefeu from "scenes/invitation-parefeu/index.jsx";
import MesParefeux from "scenes/mes-parefeux/index.jsx";

function App() {
  const { i18n } = useTranslation();
  const { currentLanguage, changeLanguage } = useLanguage();

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage, i18n]);

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const isAdminAuthenticated = useSelector((state) => state.adminAuth.isAuthenticated);
  const isUserAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);
  const userId = useSelector((state) => state.userAuth.user?.ID_Utilisateur);

  return (
    <LanguageContext.Provider value={{ currentLanguage, changeLanguage: handleLanguageChange }}>
      <div className="app">
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route element={<PrivateRoute><Layout /></PrivateRoute>}>
                <Route path="/accueil" element={<Dashboard />} />
                <Route path="/secteur" element={<Secteur />} />
                <Route path="/feedback" element={<SubscriptionRoute><FeedbackApp /></SubscriptionRoute>} /> {/* Feedback avec vérification d'abonnement */}
                <Route path="/secteurIT" element={<SecteurIT />} />
                <Route path="/secteurSG" element={<SecteurSG />} />
                <Route path="/signalement" element={<Signalements />} />
                <Route path="/signalement_sg" element={<SignalementsSG />} />
                <Route path="/incident_it" element={<IncidentIT />} />
                <Route path="/incident_sg" element={<IncidentSG />} />
                <Route path="/personnalisation" element={<Personnalisation />} />
                <Route path="/statistique" element={<SubscriptionRoute><Statistique /></SubscriptionRoute>} /> {/* Statistiques avec vérification d'abonnement */}
                <Route path="/admin" element={<Admin />} />
                <Route path="/service_it" element={<ServiceIT />} />
                <Route path="/service_sg" element={<ServiceSG />} />
                <Route path="/portail_it" element={<SubscriptionRoute><PortailIT /></SubscriptionRoute>} /> {/* Portail IT avec vérification */}
                <Route path="/portail_sg" element={<SubscriptionRoute><PortailSG /></SubscriptionRoute>} /> {/* Portail SG avec vérification */}
                <Route path="/partenaire" element={<SubscriptionRoute><Partenaires /></SubscriptionRoute>} />
                <Route path="/developer" element={<SubscriptionRoute><Developer/></SubscriptionRoute>}/>
                <Route path="/parefeu" element={<SubscriptionRoute><Parefeu/></SubscriptionRoute>}/>
              </Route>

              {/* Routes utilisateurs */}
              <Route element={<UserRoute isAuthenticated={isUserAuthenticated}><Layout /></UserRoute>}>
                <Route path="/listeetablissement" element={<ListeEtablissement />} />
                <Route path="/listetickets" element={<ListeTickets />} />
                <Route path="/infoutilisateur" element={<InformationsPersonnel />} />
                <Route path="/services" element={<Services />} />
                <Route path="/abonnement" element={<AbonnementsWithoutBackground/>} />
                <Route path='/souscription-etablissement/:type' element={<SouscriptionEtablissement/>}/>
                <Route path='/verifyportail' element={<VerifyPortail/>}/>
                <Route path='/mes-parefeux' element={<MesParefeux/>}/>
                <Route path="/invitation-parefeu/:token" element={<InvitationParefeu/>} />
              </Route>

              <Route element={<UserRoute isAuthenticated={isUserAuthenticated}><Layout /></UserRoute>}>
                <Route element={<UserRoute isAuthenticated={isAdminAuthenticated}><Layout /></UserRoute>}>
                  <Route path="/portail_client/:ID_Portail" element={<SubscriptionRoute><PagePortailAdmin /></SubscriptionRoute>} /> {/* Portail Client avec vérification */}
                </Route>
              </Route>

              {/* Routes publiques */}
              <Route path="/login/:id" element={<Login />} />                               {/* Cette route sert pour la connexion aux établissements */}
              <Route path="/loginuser/" element={<LoginUser />} />                          {/* Cette route sert pour la connexion de l'utilisateur pour sa partie personnelle */}
              <Route path="/loginuser/:ID_Etablissement" element={<LoginUserPerEtablissement />} />
              <Route path='/admindashboard' element={isAdminAuthenticated ? <AdminDashboard /> : <AdminLogin />} />
              <Route path='/adminlogin' element={<AdminLogin/>} />
              <Route path='/listeetablissement/' element={<ListeEtablissement />} />        { /* C'est la page avec toutes les connexions aux différents établissement possibles de l'utilisateur */}
              <Route path="/inscription" element={<Inscription/>} />
              <Route path='/rejoindreetablissement' element={<RejoindreEtablissement/>} />
              <Route path='/inscriptionetablissement' element={<InscriptionEtablissement/>} />
              <Route path="/subscriptionresult" element={<SubscriptionResult/>}/>
              <Route path='/loginclient' element={<LoginClient/>}/>
              <Route path='/inscriptionclient' element={<InscriptionClient/>}/>
              <Route path="/abonnements" element={<Abonnements/>}/>
              <Route path="/apropos" element={<APropos/>}/>
              <Route path='/privacy' element={<PrivacyPolitic/>}/>
              <Route path='/docdeveloper' element={<DocDeveloper/>}/>
              <Route path="/" element={<Navigate to="/accueilpublic" replace />} />
              
            </Routes>
          
          <Routes>
            <Route path="/client/:ID_Noeud/:ID_Etablissement" element={<FormulaireFrontAcceuil/>}/>
            <Route path="/client/signalement/:ID_Noeud/:ID_Etablissement" element={<PagePrincipalSignalement/>}/>
            <Route path="/client/services/:ID_Etablissement" element={<PagePrincipalServices/>}/>
            <Route path="/client/feedback/:ID_Noeud/:ID_Etablissement" element={<PagePrincipalFeedback/>}/>
            <Route path="/client/equipementparticulier/:ID_Ep_IT/:ID_Ep_SG/:ID_Etablissement" element={<EquipementParticulierForm/>}/>
            <Route path="/client/:ID_Noeud/:ID_Etablissement" element={<FormulaireFrontAcceuil/>}/>
            <Route path="/client/signalement/:id" element={<ClientS/>}/>                    {/* C'est la route qui sert à afficher la partie grand public pour faire les signalements */}    
            <Route path="/client/equipementparticulier/:id" element={<ClientE/>}/>          {/* C'est la page pour des signalements spécifiques comme pour une imprimante ou autres */}
            <Route path="/accueilpublic" element={<AcceuilPublic/>} />
            <Route path="/verifyemail" element={<VerifyEmail/>} />
            
          </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </div>
    </LanguageContext.Provider>
  );
}

export default App;
