import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, TextField, Box, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePublicGetAllFeedbackMutation, useAllInfoNoeudMutation, usePublicGetInfoRegleSpecialMutation } from 'state/api';
import WebFont from 'webfontloader';
import FeedbackCard from './FeedbackCard';
import ValidationMessage from '../ValidationMessage';
import ErrorMessage from '../ErrorMessage';
import FeedbackDetail from './FeedbackDetail';
import LogoDisplay from '../LogoDisplay';

// Utilisation des variables d'environnement pour l'URL de base
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const StyledPage = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'} !important;
  color: ${(props) => props.couleur_ecriture || '#000000'} !important;
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  width: 100vw;
  height: 100vh;
  overflow: auto !important;
  align-items: center;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.couleur_ecriture || '#000000'};
    border-radius: 10px;
  }
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin-top: 5%;
  width: 60%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const LogoContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;

  img {
    max-height: 60px;
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

const MainContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  padding-left: 32px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 60%;

  @media (max-width: 767px) {
    width: 100%;
    padding-left: 16px;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.couleur_ecriture || '#000000'};
    border-radius: 10px;
  }
`;

const FeedbackGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 16px;
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  @media (max-width: 767px) {
    width: 100%;
  }
  background-color: ${(props) => props.couleur_de_fond};
  border-radius: 5px;
  margin-bottom: 16px;
`;

const FeedbackDetailContainer = styled(Box)`
  position: relative;
  width: 100%;
  padding: 16px;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};

  &::-webkit-scrollbar {
    width: 12px;
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.couleur_ecriture || '#000000'};
    border-radius: 10px;
  }
`;

const AccessDeniedMessage = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffffff;

  .access-denied-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ff0000;
    padding: 20px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 2rem;
    position: relative;

    .icon {
      font-size: 4rem;
      margin-bottom: 16px;
    }

    .home-button {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
`;

const PagePrincipalFeedback = () => {
  const { ID_Etablissement, ID_Noeud } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userSession = useSelector((state) => state.userAuth);
  const [getAllFeedback] = usePublicGetAllFeedbackMutation();
  const [getInfoWithNoeudCheck] = useAllInfoNoeudMutation();
  const [getInfoRegleSpecial] = usePublicGetInfoRegleSpecialMutation();
  const [personnalisation, setPersonnalisation] = useState({});
  const [localFeedbacks, setLocalFeedbacks] = useState([]);
  const [globalFeedbacks, setGlobalFeedbacks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [validationMessage, setValidationMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [accessDenied, setAccessDenied] = useState(false);
  const redirecting = useRef(false);

  useEffect(() => {
    const checkRegleSpecial = async () => {
      try {
        const regleSpecial = await getInfoRegleSpecial({ ID_Etablissement }).unwrap();
        
        if (regleSpecial.ConnexionPrive) {
          if (!userSession.isAuthenticated || !userSession.user || !userSession.user.email) {
            const redirectPath = encodeURIComponent(window.location.pathname);
            if (!redirecting.current) {
              redirecting.current = true;
              navigate('/loginuser');
            }
            return;
          }

          const userDomain = userSession.user.email.split('@').pop();
          const isDomainAllowed = regleSpecial.DomainePrive.some(domain => userDomain.endsWith(domain.trim()));

          if (!isDomainAllowed) {
            setAccessDenied(true);
            return;
          }
        }

        fetchFeedbacks();
        fetchPersonnalisation();
      } catch (error) {
        setErrorMessage(t('PagePrincipalFeedback.error_checking_special_rules'));
      }
    };

    const fetchFeedbacks = async () => {
      try {
        const feedbacks = await getAllFeedback({ ID_Etablissement, ID_Noeud }).unwrap();
        const localFeedbacks = feedbacks.filter(feedback => feedback.ID_Noeud.includes(ID_Noeud));
        const globalFeedbacks = feedbacks.filter(feedback => feedback.ID_Noeud.includes(0));
        setLocalFeedbacks(localFeedbacks);
        setGlobalFeedbacks(globalFeedbacks);
      } catch (error) {
        setErrorMessage(t('PagePrincipalFeedback.error_fetch_feedbacks'));
      }
    };

    const fetchPersonnalisation = async () => {
      try {
        const data = await getInfoWithNoeudCheck({ ID_Noeud, ID_Etablissement }).unwrap();
        setPersonnalisation(data.personnalisation || {});
        if (data.personnalisation && data.personnalisation.URL_police_de_caractere) {
          WebFont.load({
            google: {
              families: [data.personnalisation.URL_police_de_caractere],
            },
          });
        }
      } catch (error) {
        setErrorMessage(t('PagePrincipalFeedback.error_fetch_personalization'));
      }
    };

    checkRegleSpecial();
  }, [ID_Etablissement, ID_Noeud, getAllFeedback, getInfoWithNoeudCheck, getInfoRegleSpecial, userSession, navigate, t]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFeedbackClick = (feedback) => {
    setSelectedFeedback(feedback);
  };

  const handleCloseDetail = () => {
    setSelectedFeedback(null);
  };

  const handleHomeClick = () => {
    navigate('/infoutilisateur');
  };

  const filteredLocalFeedbacks = localFeedbacks.filter(feedback =>
    feedback.Nom_Feedback.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredGlobalFeedbacks = globalFeedbacks.filter(feedback =>
    feedback.Nom_Feedback.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (accessDenied) {
    return (
      <AccessDeniedMessage>
        <Button
          variant="contained"
          color="primary"
          startIcon={<HomeIcon />}
          onClick={handleHomeClick}
          className="home-button"
          sx={{ position: 'fixed', top: 10, left: 10 }}
        >
          {t('PagePrincipalFeedback.home')}
        </Button>
        <div className="access-denied-box">
          <div className="icon">⚠️</div>
          {t('PagePrincipalFeedback.access_denied')}
        </div>
      </AccessDeniedMessage>
    );
  }

  return (
    <StyledPage
      couleur_de_fond={personnalisation.couleur_de_fond}
      couleur_ecriture={personnalisation.couleur_ecriture}
      police_de_caractere={personnalisation.police_de_caractere}
    >
      {selectedFeedback ? (
        <FeedbackDetailContainer
          couleur_de_fond={personnalisation.couleur_de_fond}
          couleur_ecriture={personnalisation.couleur_ecriture}
        >
          <IconButton onClick={handleCloseDetail} style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
          <FeedbackDetail
            feedback={selectedFeedback}
            couleur_de_fond={personnalisation.couleur_de_fond}
            couleur_ecriture={personnalisation.couleur_ecriture}
            setValidationMessage={setValidationMessage}
            handleCloseDetail={handleCloseDetail}
          />
        </FeedbackDetailContainer>
      ) : (
        <>
          <HeaderContainer>
            <LogoDisplay 
              logo={personnalisation.Logo}
              baseUrl={BASE_URL}
            />
            <Typography
              variant="h4"
              style={{
                color: personnalisation.couleur_ecriture,
                fontFamily: personnalisation.police_de_caractere,
                fontWeight: 'bold',
                marginBottom: '16px',
              }}
            >
              {t('PagePrincipalFeedback.consult_feedbacks')}
            </Typography>
            <StyledTextField
              placeholder={t('PagePrincipalFeedback.search_placeholder')}
              value={searchQuery}
              onChange={handleSearchChange}
              couleur_de_fond={personnalisation.couleur_de_fond}
              couleur_ecriture={personnalisation.couleur_ecriture}
              InputProps={{
                style: { color: personnalisation.couleur_ecriture },
              }}
            />
          </HeaderContainer>
          <MainContainer
            couleur_de_fond={personnalisation.couleur_de_fond}
            couleur_ecriture={personnalisation.couleur_ecriture}
          >
            <FeedbackGrid>
              {filteredLocalFeedbacks.concat(filteredGlobalFeedbacks).map((feedback) => (
                <FeedbackCard
                  key={feedback.ID_Feedback}
                  feedback={feedback}
                  couleur_de_case={personnalisation.couleur_de_case}
                  couleur_ecriture={personnalisation.couleur_ecriture}
                  couleur_dombre_de_case={personnalisation.couleur_dombre_de_case}
                  onClick={() => handleFeedbackClick(feedback)}
                />
              ))}
            </FeedbackGrid>
          </MainContainer>
        </>
      )}
      {validationMessage && (
        <ValidationMessage message={validationMessage} setValidation={setValidationMessage} />
      )}
      {errorMessage && (
        <ErrorMessage message={errorMessage} setError={setErrorMessage} />
      )}
    </StyledPage>
  );
};

export default PagePrincipalFeedback;
