import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, FormControl } from '@mui/material';
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage, selectCurrentLanguage } from '../state/languageSlice';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(selectCurrentLanguage);

  useEffect(() => {
    console.log('useEffect triggered');
    console.log('=== Language Debug ===');
    console.log('Current i18n language:', i18n.language);
    console.log('Current Redux language:', currentLanguage);
    console.log('localStorage language:', localStorage.getItem('userLanguage'));
    console.log('sessionStorage language:', sessionStorage.getItem('userLanguage'));
  }, [i18n.language, currentLanguage]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(setLanguage(lng));
    sessionStorage.setItem('userLanguage', lng);
    localStorage.setItem('userLanguage', lng);
  };

  const renderFlag = (countryCode) => (
    <ReactCountryFlag
      countryCode={countryCode === 'en' ? 'GB' : 'FR'}
      svg
      style={{
        width: '2em',
        height: '2em',
      }}
    />
  );

  return (
    <FormControl variant="outlined" sx={{ minWidth: 50, border: 'none', boxShadow: 'none' }}>
      <Select
        value={currentLanguage || 'fr'}
        onChange={(e) => changeLanguage(e.target.value)}
        displayEmpty
        inputProps={{ 'aria-label': 'Select Language' }}
        sx={{
          border: 'none',
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
        }}
      >
        <MenuItem value="en" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {renderFlag('en')}
        </MenuItem>
        <MenuItem value="fr" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {renderFlag('fr')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
