import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TextField, Box, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import styled from '@emotion/styled';
import { useParams, useNavigate } from 'react-router-dom';
import { usePublicSignalementAllInfoMutation, usePublicAddIncidentMutation, usePublicGetInfoRegleSpecialMutation } from 'state/api';
import { useSelector } from 'react-redux';
import WebFont from 'webfontloader';
import CategoriePrincipaleCard from './CategoriePrincipaleCard';
import EquipementParticulierCard from './EquipementParticulierCard';
import CategorieSecondaireView from './CategorieSecondaireView';
import ValidationMessage from '../ValidationMessage';
import ErrorMessage from '../ErrorMessage';
import IncidentForm from './IncidentForm';
import LogoDisplay from '../LogoDisplay';

// Utilisation des variables d'environnement pour l'URL de base
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

// Styled components for custom styling
const StyledPage = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'} !important;
  color: ${(props) => props.couleur_ecriture || '#000000'} !important;
  font-family: ${(props) => props.police_de_caractere}, sans-serif;
  width: 100vw;
  height: 100vh;
  overflow: auto !important;
  align-items: center;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.couleur_ecriture || '#000000'};
    border-radius: 10px;
  }
`;


const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin-top: 5%;
  width: 60%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const MainContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  padding-left: 32px;
  width: 60%;

  @media (max-width: 767px) {
    width: 100%;
    padding-left: 16px;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.couleur_ecriture || '#000000'};
    border-radius: 10px;
  }
`;

const SignalementGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 16px;
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  @media (max-width: 767px) {
    width: 100%;
  }
  background-color: ${(props) => props.couleur_de_fond};
  border-radius: 5px;
  margin-bottom: 16px;
`;

const AccessDeniedMessage = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffffff;

  .access-denied-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ff0000;
    padding: 20px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 2rem;
    position: relative;

    .icon {
      font-size: 4rem;
      margin-bottom: 16px;
    }

    .home-button {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
`;

const PagePrincipalSignalement = () => {
  const { t } = useTranslation();
  const { ID_Etablissement, ID_Noeud } = useParams();
  const navigate = useNavigate();
  const [getSignalementAllInfo] = usePublicSignalementAllInfoMutation();
  const [publicAddIncident] = usePublicAddIncidentMutation();
  const [getInfoRegleSpecial] = usePublicGetInfoRegleSpecialMutation();
  const [personnalisation, setPersonnalisation] = useState({});
  const [categoriesPrincipales, setCategoriesPrincipales] = useState([]);
  const [categoriesSecondaires, setCategoriesSecondaires] = useState([]);
  const [equipements, setEquipements] = useState([]);
  const [equipementsParticuliers, setEquipementsParticuliers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategorie, setSelectedCategorie] = useState(null);
  const [selectedEquipement, setSelectedEquipement] = useState(null);
  const [selectedEquipementParticulier, setSelectedEquipementParticulier] = useState(null);
  const [showIncidentForm, setShowIncidentForm] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [accessDenied, setAccessDenied] = useState(false);
  const redirecting = useRef(false);

  const userSession = useSelector((state) => state.userAuth);

  useEffect(() => {
    const checkRegleSpecial = async () => {
      try {
        const regleSpecial = await getInfoRegleSpecial({ ID_Etablissement }).unwrap();
        
        if (regleSpecial.ConnexionPrive) {
          if (!userSession.isAuthenticated || !userSession.user || !userSession.user.email) {
            const redirectPath = encodeURIComponent(window.location.pathname);
            if (!redirecting.current) {
              redirecting.current = true;
              navigate(`/loginclient?redirectTo=${redirectPath}`);
            }
            return;
          }

          const userDomain = userSession.user.email.split('@').pop();
          const isDomainAllowed = regleSpecial.DomainePrive.some(domain => userDomain.endsWith(domain.trim()));

          if (!isDomainAllowed) {
            setAccessDenied(true);
            return;
          }
        }

        fetchSignalements();
      } catch (error) {
        console.error(t('PagePrincipalSignalement.error_checking_special_rules'), error);
        setErrorMessage(t('PagePrincipalSignalement.error_checking_special_rules'));
      }
    };

    const fetchSignalements = async () => {
      try {
        const signalements = await getSignalementAllInfo({ ID_Etablissement, ID_Noeud }).unwrap();

        setCategoriesPrincipales(signalements.categoriesPrincipales);
        setCategoriesSecondaires(signalements.categoriesSecondaires);
        setEquipements(signalements.equipements);
        setEquipementsParticuliers(signalements.equipementsParticuliers);
        setPersonnalisation(signalements.personnalisation);

        if (signalements.personnalisation && signalements.personnalisation.URL_police_de_caractere) {
          WebFont.load({
            google: {
              families: [signalements.personnalisation.URL_police_de_caractere],
            },
          });
        }
      } catch (error) {
        console.error(t('PagePrincipalSignalement.error_fetching_signalements'), error);
        setErrorMessage(t('PagePrincipalSignalement.error_fetching_signalements'));
      }
    };

    checkRegleSpecial();
  }, [ID_Etablissement, ID_Noeud, getSignalementAllInfo, getInfoRegleSpecial, userSession, navigate, t]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCategorieClick = (categorie) => {
    setSelectedCategorie(categorie);
  };

  const handleEquipementSelect = (equipement) => {
    setSelectedEquipement(equipement);
    setShowIncidentForm(true);
  };

  const handleEquipementParticulierSelect = (equipement) => {
    setSelectedEquipementParticulier(equipement);
    setShowIncidentForm(true);
  };

  const handleBackToCategories = () => {
    setSelectedCategorie(null);
    setSelectedEquipement(null);
    setSelectedEquipementParticulier(null);
  };

  const handleHideIncidentForm = () => {
    setShowIncidentForm(false);
    setSelectedEquipement(null);
    setSelectedEquipementParticulier(null);
  };

  const handleIncidentFormSubmit = async (formData) => {
    const isIT = selectedEquipementParticulier.ID_Ep_IT !== undefined;
    const type = isIT ? 'IT' : 'SG';
  
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('type', type);
      formDataToSend.append('ID_Etablissement', ID_Etablissement);
      formDataToSend.append('ID_Noeud', ID_Noeud);
      formDataToSend.append('ID_Ep_IT', isIT ? selectedEquipementParticulier.ID_Ep_IT : '');
      formDataToSend.append('ID_Type_Daction_IT', isIT ? selectedEquipementParticulier.ID_Type_Daction_IT : '');
      formDataToSend.append('ID_Ep_SG', !isIT ? selectedEquipementParticulier.ID_Ep_SG : '');
      formDataToSend.append('ID_Type_Daction_SG', !isIT ? selectedEquipementParticulier.ID_Type_Daction_SG : '');
      formDataToSend.append('Priorite', selectedEquipementParticulier.Priorite);
      formDataToSend.append('Severite', selectedEquipementParticulier.Severite);
      formDataToSend.append('ID_Utilisateur', userSession.user.ID_Utilisateur ? userSession.user.ID_Utilisateur : null);
  
      // Append other form data
      Object.keys(formData).forEach(key => {
        if (key === 'Attachment' && formData[key]) {
          formDataToSend.append(key, formData[key], formData[key].name);
        } else {
          formDataToSend.append(key, formData[key]);
        }
      });
  
      await publicAddIncident(formDataToSend);
      setValidationMessage(t('PagePrincipalSignalement.incident_submitted_successfully'));
      handleHideIncidentForm();
    } catch (error) {
      console.error(t('PagePrincipalSignalement.error_submitting_incident'), error);
      setValidationMessage(t('PagePrincipalSignalement.error_submitting_incident'));
    }
  };

  const handleHomeClick = () => {
    navigate('/infoutilisateur');
  };

  const filteredItems = selectedCategorie
    ? [
        ...categoriesSecondaires.filter(cat =>
          cat.ID_Categorie_Precedente === selectedCategorie.ID_Categorie_Signalement_IT ||
          cat.ID_Categorie_Precedente === selectedCategorie.ID_Categorie_Signalement_SG
        ),
        ...equipements.filter(equipement =>
          equipement.ID_Categorie_Signalement === selectedCategorie.ID_Categorie_Signalement_IT ||
          equipement.ID_Categorie_Signalement === selectedCategorie.ID_Categorie_Signalement_SG
        )
      ]
    : [
        ...categoriesPrincipales.filter(categorie =>
          categorie.Nom_Categorie.toLowerCase().includes(searchQuery.toLowerCase())
        ),
        ...equipementsParticuliers.filter(equipement =>
          equipement.Nom_Ep.toLowerCase().includes(searchQuery.toLowerCase())
        )
      ];

  if (accessDenied) {
    return (
      <AccessDeniedMessage>
        <Button
          variant="contained"
          color="primary"
          startIcon={<HomeIcon />}
          onClick={handleHomeClick}
          className="home-button"
          sx={{ position: 'fixed', top: 10, left: 10 }}
        >
          {t('PagePrincipalSignalement.home_button')}
        </Button>
        <div className="access-denied-box">
          <div className="icon">⚠️</div>
          {t('PagePrincipalSignalement.access_denied')}
        </div>
      </AccessDeniedMessage>
    );
  }

  return (
    <StyledPage
      couleur_de_fond={personnalisation.couleur_de_fond}
      couleur_ecriture={personnalisation.couleur_ecriture}
      police_de_caractere={personnalisation.police_de_caractere}
    >
      {showIncidentForm ? (
        <IncidentForm
          onClose={handleHideIncidentForm}
          couleur_de_fond={personnalisation.couleur_de_fond}
          couleur_ecriture={personnalisation.couleur_ecriture}
          couleur_bouton={personnalisation.couleur_bouton}
          couleur_bouton_ecriture={personnalisation.couleur_bouton_ecriture}
          police_de_caractere={personnalisation.police_de_caractere}
          logoUrl={personnalisation.Logo}
          onFormSubmit={handleIncidentFormSubmit}
          maxParticules={selectedEquipement ? selectedEquipement.Nombre_particules : 1}
        />
      ) : (
        <>
          <HeaderContainer>
            <LogoDisplay 
              logo={personnalisation.Logo}
              baseUrl={BASE_URL}
              maxHeight="60px"
            />
            <Typography
              variant="h4"
              style={{
                color: personnalisation.couleur_ecriture,
                fontFamily: personnalisation.police_de_caractere,
                fontWeight: 'bold',
                marginBottom: '16px',
              }}
            >
              {t('PagePrincipalSignalement.check_reports')}
            </Typography>
            <StyledTextField
              placeholder={t('PagePrincipalSignalement.search_placeholder')}
              value={searchQuery}
              onChange={handleSearchChange}
              couleur_de_fond={personnalisation.couleur_de_fond}
              couleur_ecriture={personnalisation.couleur_ecriture}
              InputProps={{
                style: { color: personnalisation.couleur_ecriture },
              }}
            />
          </HeaderContainer>
          <MainContainer
            couleur_de_fond={personnalisation.couleur_de_fond}
            couleur_ecriture={personnalisation.couleur_ecriture}
          >
            {selectedCategorie ? (
              <CategorieSecondaireView
                categorie={selectedCategorie}
                categoriesSecondaires={categoriesSecondaires}
                equipements={equipements}
                onBack={handleBackToCategories}
                onCategorieClick={handleCategorieClick}
                personnalisation={personnalisation}
                logoUrl={personnalisation.Logo}
                userSession={userSession}
                ID_Etablissement={ID_Etablissement}
                ID_Noeud={ID_Noeud}
                publicAddIncident={publicAddIncident}
              />
            ) : (
              <SignalementGrid>
                {filteredItems.map((item) => {
                  if ('Nom_Categorie' in item) {
                    const key = item.ID_Categorie_Signalement_IT || item.ID_Categorie_Signalement_SG;
                    return (
                      <CategoriePrincipaleCard
                        key={`categorie-${key}`}
                        categorie={item}
                        type={item.ID_Categorie_Signalement_IT ? 'IT' : 'SG'}
                        onClick={() => handleCategorieClick(item)}
                        couleur_de_case={personnalisation.couleur_de_case}
                        couleur_ecriture={personnalisation.couleur_ecriture}
                        couleur_dombre_de_case={personnalisation.couleur_dombre_de_case}
                      />
                    );
                  } else {
                    const key = item.ID_Ep_IT || item.ID_Ep_SG;
                    return (
                      <EquipementParticulierCard
                        key={`equipement-${key}`}
                        equipement={item}
                        type={item.ID_Ep_IT ? 'IT' : 'SG'}
                        onClick={() => handleEquipementParticulierSelect(item)}
                        couleur_de_fond={personnalisation.couleur_de_fond}
                        couleur_ecriture={personnalisation.couleur_ecriture}
                        couleur_dombre_de_case={personnalisation.couleur_dombre_de_case}
                        couleur_bouton_ecriture={personnalisation.couleur_ecriture}
                        police_de_caractere={personnalisation.police_de_caractere}
                      />
                    );
                  }
                })}
              </SignalementGrid>
            )}
          </MainContainer>
          {validationMessage && (
            <ValidationMessage message={validationMessage} setValidation={setValidationMessage} />
          )}
          {errorMessage && (
            <ErrorMessage message={errorMessage} setError={setErrorMessage} />
          )}
        </>
      )}
    </StyledPage>
  );
};

export default PagePrincipalSignalement;
