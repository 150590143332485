import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, List, ListItem, Paper, IconButton, Tooltip, InputAdornment } from '@mui/material';
import { useGetParefeuByEtablissementIdQuery, useCreateParefeuMutation, useUpdateParefeuMutation, useGetParefeuConfigQuery, useGenererTokenInvitationMutation } from '../../state/api';
import { useSelector } from 'react-redux';
import ValidationMessage from '../../components/ValidationMessage';
import ErrorMessage from '../../components/ErrorMessage';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

const Parefeu = () => {
  const { t } = useTranslation();
  const [whiteList, setWhiteList] = useState([]);
  const [blackList, setBlackList] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [newWhiteListItem, setNewWhiteListItem] = useState('');
  const [newBlackListItem, setNewBlackListItem] = useState('');
  const [error, setError] = useState('');
  const [validation, setValidation] = useState('');
  const [whiteListSearch, setWhiteListSearch] = useState('');
  const [blackListSearch, setBlackListSearch] = useState('');
  const [invitationUrl, setInvitationUrl] = useState('');
  const [invitationToken, setInvitationToken] = useState('');

  const userSession = useSelector((state) => state.adminAuth);

  const { data: parefeuData, isLoading } = useGetParefeuByEtablissementIdQuery(userSession?.ID_Etablissement);
  const { data: configData } = useGetParefeuConfigQuery(userSession?.ID_Etablissement);
  const [createParefeu] = useCreateParefeuMutation();
  const [updateParefeu] = useUpdateParefeuMutation();
  const [genererTokenInvitation] = useGenererTokenInvitationMutation();

  useEffect(() => {
    if (parefeuData) {
      setWhiteList(parefeuData.whitelist || []);
      setBlackList(parefeuData.blacklist || []);
      setRedirectUrl(parefeuData.url_api || '');
    }
  }, [parefeuData]);

  useEffect(() => {
    if (userSession?.ID_Etablissement) {
      const baseUrl = window.location.origin;
      setInvitationUrl(`${baseUrl}/invitation-parefeu/${userSession.ID_Etablissement}`);
    }
  }, [userSession]);

  const handleDownloadConfig = () => {
    if (configData) {
      const configToDownload = {
        whitelist: configData.whitelist || [],
        blacklist: configData.blacklist || [],
        url_api: configData.url_api || ''
      };

      const jsonString = JSON.stringify(configToDownload, null, 2);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'parefeu-config.json';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
      setValidation(t('success.configDownloaded'));
    } else {
      setError(t('errors.configDownload'));
    }
  };

  const validateDomainOnly = (url) => {
    const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)(?:\/.*)?$/;
    const match = url.match(domainRegex);
    return match ? match[1] : null;
  };

  const validateFullUrl = (url) => {
    const urlRegex = /^https?:\/\/.+/;
    return urlRegex.test(url);
  };

  const handleUpdateWhiteList = async () => {
    if (newWhiteListItem) {
      const domainOnly = validateDomainOnly(newWhiteListItem);
      if (!domainOnly) {
        setError(t('errors.invalidWhitelistDomain'));
        return;
      }

      if (whiteList.includes(domainOnly)) {
        setError(t('errors.domainAlreadyInWhitelist'));
        return;
      }

      const updatedWhiteList = [...whiteList, domainOnly];
      try {
        if (parefeuData) {
          await updateParefeu({
            id: userSession.ID_Etablissement,
            whitelist: updatedWhiteList
          });
        } else {
          await createParefeu({
            whitelist: updatedWhiteList,
            blacklist: blackList,
            url_api: redirectUrl,
            ID_Etablissement: userSession.ID_Etablissement
          });
        }
        setWhiteList(updatedWhiteList);
        setNewWhiteListItem('');
        setValidation(t('success.whitelistUpdated'));
      } catch (error) {
        setError(t('errors.whitelistUpdate'));
      }
    }
  };

  const handleUpdateBlackList = async () => {
    if (newBlackListItem) {
      const domainOnly = validateDomainOnly(newBlackListItem);
      if (!domainOnly) {
        setError(t('errors.invalidBlacklistDomain'));
        return;
      }

      if (blackList.includes(domainOnly)) {
        setError(t('errors.domainAlreadyInBlacklist'));
        return;
      }

      const updatedBlackList = [...blackList, domainOnly];
      try {
        if (parefeuData) {
          await updateParefeu({
            id: userSession.ID_Etablissement,
            blacklist: updatedBlackList
          });
        } else {
          await createParefeu({
            whitelist: whiteList,
            blacklist: updatedBlackList,
            url_api: redirectUrl,
            ID_Etablissement: userSession.ID_Etablissement
          });
        }
        setBlackList(updatedBlackList);
        setNewBlackListItem('');
        setValidation(t('success.blacklistUpdated'));
      } catch (error) {
        setError(t('errors.blacklistUpdate'));
      }
    }
  };

  const handleRemoveFromWhiteList = async (domainToRemove) => {
    const updatedWhiteList = whiteList.filter(domain => domain !== domainToRemove);
    try {
      await updateParefeu({
        id: userSession.ID_Etablissement,
        whitelist: updatedWhiteList
      });
      setWhiteList(updatedWhiteList);
      setValidation(t('success.domainRemovedFromWhitelist'));
    } catch (error) {
      setError(t('errors.whitelistDomainRemoval'));
    }
  };

  const handleRemoveFromBlackList = async (domainToRemove) => {
    const updatedBlackList = blackList.filter(domain => domain !== domainToRemove);
    try {
      await updateParefeu({
        id: userSession.ID_Etablissement,
        blacklist: updatedBlackList
      });
      setBlackList(updatedBlackList);
      setValidation(t('success.domainRemovedFromBlacklist'));
    } catch (error) {
      setError(t('errors.blacklistDomainRemoval'));
    }
  };

  const handleUpdateRedirectUrl = async () => {
    if (!validateFullUrl(redirectUrl)) {
      setError(t('errors.invalidRedirectUrl'));
      return;
    }

    try {
      if (parefeuData) {
        await updateParefeu({
          id: userSession.ID_Etablissement,
          url_api: redirectUrl
        });
      } else {
        await createParefeu({
          whitelist: whiteList,
          blacklist: blackList,
          url_api: redirectUrl,
          ID_Etablissement: userSession.ID_Etablissement
        });
      }
      setValidation(t('success.redirectUrlUpdated'));
    } catch (error) {
      setError(t('errors.redirectUrlUpdate'));
    }
  };

  const handleCopyInvitationUrl = () => {
    navigator.clipboard.writeText(invitationUrl);
    setValidation(t('success.invitationUrlCopied'));
  };

  const handleGenererInvitation = async () => {
    try {
      const response = await genererTokenInvitation({
        ID_Etablissement: userSession.ID_Etablissement
      }).unwrap();
      
      setInvitationUrl(response.invitationUrl);
      setValidation(t('success.newInvitationGenerated'));
    } catch (error) {
      setError(t('errors.invitationGeneration'));
    }
  };

  const filteredWhiteList = whiteList.filter(domain => 
    domain.toLowerCase().includes(whiteListSearch.toLowerCase())
  );

  const filteredBlackList = blackList.filter(domain => 
    domain.toLowerCase().includes(blackListSearch.toLowerCase())
  );

  if (isLoading) return <Typography>{t('common.loading')}</Typography>;

  return (
    <Box p={3}>
      <ValidationMessage message={validation} setValidation={setValidation} />
      <ErrorMessage message={error} setError={setError} />

      <Typography variant="h4" gutterBottom>{t('parefeu.configTitle')}</Typography>

      <Button 
        variant="contained" 
        onClick={handleDownloadConfig}
        startIcon={<DownloadIcon />}
        sx={{ mb: 3 }}
      >
        {t('parefeu.downloadConfig')}
      </Button>

      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Box display="flex" alignItems="center" mb={1}>
          <Typography variant="h6">{t('parefeu.invitationLink')}</Typography>
          <Tooltip title={t('parefeu.invitationLinkTooltip')}>
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box display="flex" gap={1}>
          <TextField
            fullWidth
            value={invitationUrl}
            InputProps={{
              readOnly: true,
            }}
          />
          <Button variant="contained" onClick={handleGenererInvitation}>
            {t('common.generate')}
          </Button>
          <Button variant="contained" onClick={handleCopyInvitationUrl}>
            {t('common.copy')}
          </Button>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Box display="flex" alignItems="center" mb={1}>
          <Typography variant="h6">{t('parefeu.redirectUrl')}</Typography>
          <Tooltip title={t('parefeu.redirectUrlTooltip')}>
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box display="flex" gap={1}>
          <TextField
            fullWidth
            value={redirectUrl}
            onChange={(e) => setRedirectUrl(e.target.value)}
            placeholder="https://exemple.com/api"
          />
          <Button variant="contained" onClick={handleUpdateRedirectUrl}>
            {t('common.update')}
          </Button>
        </Box>
      </Paper>

      <Box display="flex" gap={4}>
        <Paper elevation={3} sx={{ p: 2, flex: 1 }}>
          <Box display="flex" alignItems="center" mb={1}>
            <Typography variant="h6">{t('parefeu.whitelist')}</Typography>
            <Tooltip title={t('parefeu.whitelistTooltip')}>
              <IconButton size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" gap={1} mb={2}>
            <TextField
              fullWidth
              value={newWhiteListItem}
              onChange={(e) => setNewWhiteListItem(e.target.value)}
              placeholder="exemple.com"
            />
            <Button variant="contained" onClick={handleUpdateWhiteList}>
              {t('common.add')}
            </Button>
          </Box>
          <TextField
            fullWidth
            value={whiteListSearch}
            onChange={(e) => setWhiteListSearch(e.target.value)}
            placeholder={t('parefeu.searchWhitelist')}
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <List>
            {filteredWhiteList.map((item, index) => (
              <ListItem 
                key={index}
                secondaryAction={
                  <IconButton edge="end" onClick={() => handleRemoveFromWhiteList(item)}>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                {item}
              </ListItem>
            ))}
          </List>
        </Paper>

        <Paper elevation={3} sx={{ p: 2, flex: 1 }}>
          <Box display="flex" alignItems="center" mb={1}>
            <Typography variant="h6">{t('parefeu.blacklist')}</Typography>
            <Tooltip title={t('parefeu.blacklistTooltip')}>
              <IconButton size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" gap={1} mb={2}>
            <TextField
              fullWidth
              value={newBlackListItem}
              onChange={(e) => setNewBlackListItem(e.target.value)}
              placeholder="exemple.com"
            />
            <Button variant="contained" onClick={handleUpdateBlackList}>
              {t('common.add')}
            </Button>
          </Box>
          <TextField
            fullWidth
            value={blackListSearch}
            onChange={(e) => setBlackListSearch(e.target.value)}
            placeholder={t('parefeu.searchBlacklist')}
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <List>
            {filteredBlackList.map((item, index) => (
              <ListItem 
                key={index}
                secondaryAction={
                  <IconButton edge="end" onClick={() => handleRemoveFromBlackList(item)}>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                {item}
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
    </Box>
  );
};

export default Parefeu;
