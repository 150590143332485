import React, { useEffect, useState } from 'react';
import { Typography, TextField, Box, Button, Checkbox, FormControlLabel, RadioGroup, Radio, Select, MenuItem, Slider, Container } from '@mui/material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { usePublicGetFeedbackSectionsMutation, usePublicAddRetourFeedbackMutation } from 'state/api';
import ValidationMessage from '../ValidationMessage';
import LogoDisplay from '../LogoDisplay';

const FeedbackDetailContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${props => props.couleur_de_fond};
  color: ${props => props.couleur_ecriture};
`;

const SectionContainer = styled(Box)`
  width: 100%;
`;

const InputContainer = styled(Box)`
  margin-bottom: 25px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const InstructionText = styled(Typography)`
  font-weight: bold;
  margin-bottom: 10px;
`;

const IndentedContainer = styled(Box)`
  margin-left: 5%;
`;

const FeedbackDetail = ({ feedback, couleur_de_fond, couleur_ecriture, setValidationMessage, handleCloseDetail, personnalisation }) => {
  const { t } = useTranslation();
  const [getFeedbackSections] = usePublicGetFeedbackSectionsMutation();
  const [addRetourFeedback] = usePublicAddRetourFeedbackMutation();
  const [sections, setSections] = useState([]);
  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [validationMessage, setLocalValidationMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await getFeedbackSections({ ID_Feedback: feedback.ID_Feedback }).unwrap();
        setSections(response.sections);
        initializeInputValues(response.sections);
      } catch (error) {
        console.error(t('fetch_sections_error'), error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSections();
  }, [feedback.ID_Feedback, getFeedbackSections, t]);

  const initializeInputValues = (sections) => {
    const initialValues = {};
    sections.forEach((section) => {
      section.inputs.forEach((input) => {
        initialValues[input.ID_Input] = input.Type_Dinput === 'slider' ? input.Options.min : '';
      });
    });
    setInputValues(initialValues);
  };

  const handleInputChange = (inputId, value, type) => {
    setInputValues(prev => ({
      ...prev,
      [inputId]: type === 'file' ? value.target.files[0] : value
    }));
  };

  const handleSendFeedback = async () => {
    if (!validate()) {
      return;
    }

    const formData = new FormData();
    const retourFeedbackData = {
      ID_Feedback: feedback.ID_Feedback,
      ID_Etablissement: feedback.ID_Etablissement,
      Retours_de_section: sections.map(section => ({
        ID_Section: section.ID_Section,
        ID_Etablissement: feedback.ID_Etablissement,
        Retours_dinput: section.inputs.map(input => ({
          ID_Input: input.ID_Input,
          Type_Dinput: input.Type_Dinput,
          Valeur: input.Type_Dinput === 'file' ? '' : inputValues[input.ID_Input]
        }))
      }))
    };

    formData.append('retourFeedbackData', JSON.stringify(retourFeedbackData));

    Object.entries(inputValues).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append('files', value, value.name);
      }
    });

    try {
      const response = await addRetourFeedback(formData).unwrap();
      setValidationMessage(t('feedback_sent_success'));
      handleCloseDetail();
    } catch (error) {
      console.error(t('feedback_submission_error'), error);
    }
  };

  const validate = () => {
    const newErrors = {};
    sections.forEach((section) => {
      section.inputs.forEach((input) => {
        if (input.Obligatoire && !inputValues[input.ID_Input]) {
          newErrors[input.ID_Input] = t('field_required');
        }
        if (input.Type_Dinput === 'file' && inputValues[input.ID_Input]) {
          const file = inputValues[input.ID_Input];
          const acceptedTypes = input.Options.accept.split(',').map(type => type.trim());
          const fileExtension = file.name.split('.').pop().toLowerCase();
          if (!acceptedTypes.includes(`.${fileExtension}`)) {
            newErrors[input.ID_Input] = t('invalid_file_format', { format: fileExtension });
          }
        }
      });
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const renderInput = (input) => {
    const { ID_Input, Nom_Input, Type_Dinput, Options, Obligatoire, Instruction_Input } = input;
    const value = inputValues[ID_Input] || '';
    const error = errors[ID_Input];

    return (
      <InputContainer>
        <InstructionText>{Instruction_Input}</InstructionText>
        {renderInputByType(Type_Dinput, ID_Input, Nom_Input, value, Options, Obligatoire, error)}
      </InputContainer>
    );
  };

  const renderInputByType = (Type_Dinput, ID_Input, Nom_Input, value, Options, Obligatoire, error) => {
    switch (Type_Dinput) {
      case 'boolean':
      case 'checkbox':
        return (
          <IndentedContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value === true}
                  onChange={(e) => handleInputChange(ID_Input, e.target.checked)}
                />
              }
              label={Nom_Input}
            />
          </IndentedContainer>
        );
      case 'slider':
        return (
          <>
            <Typography>{Nom_Input}</Typography>
            <Slider
              value={value}
              onChange={(_, newValue) => handleInputChange(ID_Input, newValue)}
              min={Options.min}
              max={Options.max}
              step={1}
              marks
              valueLabelDisplay="auto"
            />
          </>
        );
      case 'varchar':
        return (
          <TextField
            fullWidth
            label={Nom_Input}
            value={value}
            onChange={(e) => handleInputChange(ID_Input, e.target.value)}
            error={!!error}
            helperText={error}
            required={Obligatoire}
            inputProps={{ maxLength: Options.maxlength }}
            variant="outlined"
          />
        );
      case 'radio':
        return (
          <IndentedContainer>
            <RadioGroup
              value={value}
              onChange={(e) => handleInputChange(ID_Input, e.target.value)}
            >
              {Options.choices.map((choice, index) => (
                <FormControlLabel key={index} value={choice} control={<Radio />} label={choice} />
              ))}
            </RadioGroup>
          </IndentedContainer>
        );
      case 'dropdown':
        return (
          <Select
            fullWidth
            value={value}
            onChange={(e) => handleInputChange(ID_Input, e.target.value)}
            displayEmpty
            error={!!error}
            variant="outlined"
          >
            <MenuItem value="" disabled>{Nom_Input}</MenuItem>
            {Options.choices.map((choice, index) => (
              <MenuItem key={index} value={choice}>{choice}</MenuItem>
            ))}
          </Select>
        );
      case 'file':
        return (
          <IndentedContainer>
            <input
              type="file"
              accept={Options.accept}
              onChange={(e) => handleInputChange(ID_Input, e, 'file')}
            />
          </IndentedContainer>
        );
      case 'date':
        return (
          <TextField
            type="date"
            fullWidth
            label={Nom_Input}
            value={value}
            onChange={(e) => handleInputChange(ID_Input, e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        );
      case 'time':
        return (
          <TextField
            type="time"
            fullWidth
            label={Nom_Input}
            value={value}
            onChange={(e) => handleInputChange(ID_Input, e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        );
      case 'number':
        return (
          <TextField
            type="number"
            fullWidth
            label={Nom_Input}
            value={value}
            onChange={(e) => handleInputChange(ID_Input, e.target.value)}
            error={!!error}
            helperText={error}
            required={Obligatoire}
            variant="outlined"
          />
        );
      default:
        return null;
    }
  };

  return (
    <FeedbackDetailContainer maxWidth="sm" couleur_de_fond={couleur_de_fond} couleur_ecriture={couleur_ecriture}>
      <LogoDisplay 
        logo={personnalisation?.Logo_Etablissement}
        baseUrl={process.env.REACT_APP_BASE_URL || 'http://localhost:5001'}
      />
      <Typography variant="h4" gutterBottom align="center">
        {feedback.Nom_Feedback}
      </Typography>
      <Typography variant="body1" gutterBottom align="center">
        {feedback.Description_Feedback}
      </Typography>
      {isLoading ? (
        <Typography>{t('loading')}</Typography>
      ) : sections.length > 0 ? (
        sections.map((section, sectionIndex) => (
          <SectionContainer key={sectionIndex}>
            {section.inputs && section.inputs.map((input, inputIndex) => renderInput(input))}
          </SectionContainer>
        ))
      ) : (
        <Typography>{t('no_sections_available')}</Typography>
      )}
      <StyledButton variant="contained" color="primary" onClick={handleSendFeedback}>
        {t('send_feedback')}
      </StyledButton>
      {validationMessage && (
        <ValidationMessage message={validationMessage} setValidation={setLocalValidationMessage} />
      )}
    </FeedbackDetailContainer>
  );
};

export default FeedbackDetail;
