import React, { useState } from 'react';
import { Box, Typography, TextField, Grid, Button } from "@mui/material";
import styled from '@emotion/styled';
import LogoDisplay from '../../components/LogoDisplay';
import PropTypes from 'prop-types';

const DesktopWindowPreview = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
`;

const WindowHeader = styled(Box)`
  display: flex;
  align-items: center;
  background: #f0f0f0;
  height: 32px;
  padding: 0 8px;
  border-bottom: 1px solid #ddd;
`;

const WindowControls = styled(Box)`
  display: flex;
  gap: 6px;
  
  .control {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  
  .close { background: #ff5f57; }
  .minimize { background: #ffbd2e; }
  .maximize { background: #28c940; }
`;

const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  gap: 24px;
`;

const PreviewContainer = styled(Box)`
  display: flex;
  width: 100%;
  gap: 2rem;
  align-items: flex-start;
`;

const ControlsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 1rem;
`;

const BannerContainer = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${props => props.backgroundColor};
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const DesktopPreview = ({ 
  banniere,
  banniereFile, 
  personnalisationData, 
  couleur_de_fond_haut,
  couleur_decriture,
  police_de_caractere,
  setCouleur_de_fond_haut,
  setBanniereFile,
  t,
  BASE_URL,
  Logo,
  tempLogoFile,
  couleur_ecriture_banniere,
  setCouleur_ecriture_banniere,
  onDeleteBanner,
}) => {
  const [tempBannerFile, setTempBannerFile] = useState(null);

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTempBannerFile(file);
      setBanniereFile(file);
    }
  };

  const getBannerUrl = (path) => {
    if (!path) return null;
    
    if (path.startsWith('http')) {
      const match = path.match(/images\/(.*)/);
      if (match) {
        return `${BASE_URL}/images/displayimagewithpath/${encodeURIComponent(match[1])}`;
      }
      return path;
    }
    
    const imagePath = path.split('/').pop();
    return `${BASE_URL}/images/displayimagewithpath/${encodeURIComponent(imagePath)}`;
  };

  return (
    <>
      <Typography variant="h4" align="center" sx={{ my: 4 }}>
        {t('Personnalisation.previewTitle') || 'Aperçu de la page d\'accueil des signalements'}
      </Typography>
      <PreviewContainer>
        <Box sx={{ width: '80%' }}>
          <DesktopWindowPreview>
            <WindowHeader>
              <WindowControls>
                <div className="control close" />
                <div className="control minimize" />
                <div className="control maximize" />
              </WindowControls>
            </WindowHeader>
            
            <Box
              style={{
                height: 'calc(100% - 32px)',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: couleur_de_fond_haut
              }}
            >
              <Box sx={{ height: '50%', position: 'relative' }}>
                <BannerContainer backgroundColor={couleur_de_fond_haut}>
                  {(tempBannerFile || banniereFile || personnalisationData?.Banniere) && (
                    <img
                      src={
                        tempBannerFile 
                          ? URL.createObjectURL(tempBannerFile)
                          : banniereFile 
                            ? URL.createObjectURL(banniereFile)
                            : getBannerUrl(banniere)
                      }
                      alt="Bannière"
                    />
                  )}
                </BannerContainer>
                <HeaderContainer>
                  <LogoDisplay 
                    logo={Logo}
                    baseUrl={BASE_URL}
                    maxHeight="120px"
                    isTemporary={!!tempLogoFile}
                    temporaryImage={tempLogoFile}
                  />
                  <Typography 
                    variant="h4" 
                    style={{
                      color: couleur_ecriture_banniere,
                      fontFamily: `'${police_de_caractere}', sans-serif`,
                    }}
                  >
                    {t('Personnalisation.sampleTitle')}
                  </Typography>
                </HeaderContainer>
              </Box>
              
              <Box
                style={{
                  flex: 1
                }}
              >
              </Box>
            </Box>
          </DesktopWindowPreview>
        </Box>

        <Box sx={{ width: '20%', height: '500px', display: 'flex', alignItems: 'center' }}>
          <ControlsContainer>
            <TextField
              label={t('Personnalisation.backgroundColorTopLabel')}
              type="color"
              fullWidth
              value={couleur_de_fond_haut}
              onChange={(e) => setCouleur_de_fond_haut(e.target.value)}
              InputProps={{ style: { height: '56px' } }}
            />
            
            <TextField
              label={t('Personnalisation.bannerTextColorLabel')}
              type="color"
              fullWidth
              value={couleur_ecriture_banniere}
              onChange={(e) => setCouleur_ecriture_banniere(e.target.value)}
              InputProps={{ style: { height: '56px' } }}
            />
            
            <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
              {t('Personnalisation.bannerUploadLabel') || 'Choisir une bannière'}
            </Typography>
            
            <input
              type="file"
              accept="image/*"
              onChange={handleBannerChange}
            />
            
            {(personnalisationData?.Banniere || tempBannerFile || banniereFile) && (
              <Button 
                variant="outlined" 
                color="error" 
                onClick={() => {
                  setTempBannerFile(null);
                  setBanniereFile(null);
                  onDeleteBanner?.();
                }}
                sx={{ mt: 1 }}
              >
                {t('Personnalisation.deleteBanner') || 'Supprimer la bannière'}
              </Button>
            )}
          </ControlsContainer>
        </Box>
      </PreviewContainer>
    </>
  );
};

DesktopPreview.propTypes = {
  onDeleteBanner: PropTypes.func,
};

export default DesktopPreview;