import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import i18n from './state/i18n';
import { I18nextProvider } from 'react-i18next';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

if (process.env.NODE_ENV === 'development') {
  const devToolsWarning = window.__REACT_DEVTOOLS_GLOBAL_HOOK__;
  if (typeof devToolsWarning?.inject === 'function') {
    devToolsWarning.inject = function() {};
  }
}
