import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, List, ListItem, ListItemText, Button, CircularProgress, TextField, useTheme, useMediaQuery, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetUserParefeuxMutation, useUpdateParefeuxMutation, useSetParefeuActuelMutation } from 'state/api';
import NavbarUtilisateur from 'components_public/navbarUtilisateur';
import { useTranslation } from 'react-i18next';

const MesParefeux = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [parefeux, setParefeux] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const userId = useSelector(state => state.userAuth.user?.ID_Utilisateur);
  const [getUserParefeux] = useGetUserParefeuxMutation();
  const [updateParefeux] = useUpdateParefeuxMutation();
  const [setParefeuActuel] = useSetParefeuActuelMutation();

  const fetchParefeux = async () => {
    try {
      setIsLoading(true);
      const response = await getUserParefeux({
        ID_Utilisateur: userId
      });
      if (response.data?.parefeux) {
        setParefeux(response.data.parefeux);
      } else {
        setParefeux([]);
      }
      setIsLoading(false);
    } catch (err) {
      console.error("Erreur lors de la récupération des parefeux:", err);
      setError(t('errors.fetchParefeux'));
      setParefeux([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchParefeux();
    }
  }, [userId, getUserParefeux]);

  const handleSelectParefeu = async (parefeuId) => {
    try {
      await updateParefeux({
        ID_Utilisateur: userId,
        parefeuActuel: parefeuId
      }).unwrap();
      
      setSuccess(t('success.updateActiveParefeu'));
      setError('');
      fetchParefeux();
    } catch (err) {
      setError(t('errors.updateActiveParefeu'));
      setSuccess('');
    }
  };

  const handleRemoveActiveParefeu = async () => {
    try {
      await setParefeuActuel({
        ID_Utilisateur: userId,
        parefeuActuel: null
      }).unwrap();
      
      setSuccess(t('success.removeActiveParefeu'));
      setError('');
      fetchParefeux();
    } catch (err) {
      setError(t('errors.removeActiveParefeu'));
      setSuccess('');
    }
  };

  const filteredParefeux = Array.isArray(parefeux) ? parefeux.filter(parefeu => 
    parefeu.titre.toLowerCase().includes(searchTerm.toLowerCase()) ||
    parefeu.etablissement?.nom.toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];

  const activeParefeu = Array.isArray(parefeux) ? parefeux.find(p => p.isActive) : null;

  if (isLoading) {
    return (
      <Box>
        <NavbarUtilisateur />
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
          <CircularProgress sx={{ color: theme.palette.primary.main }} />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <NavbarUtilisateur />
      <Box 
        p={isMobile ? 2 : 3} 
        sx={{ 
          paddingTop: '20vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          bgcolor: theme.palette.background.default
        }}
      >
        <Box sx={{ 
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto'
        }}>
          <Box sx={{
            width: isMobile ? '100%' : '80%',
            margin: '0 auto'
          }}>
            <Typography variant="h4" mb={4} sx={{ 
              textAlign: isMobile ? 'center' : 'left',
              color: theme.palette.text.primary
            }}>{t('parefeux.title')}</Typography>
            
            {error && (
              <Typography color="error" mb={2}>{error}</Typography>
            )}
            
            {success && (
              <Typography color="success.main" mb={2}>{success}</Typography>
            )}

            <Paper elevation={3} sx={{ 
              mb: 3, 
              p: isMobile ? 2 : 3,
              bgcolor: theme.palette.background.alt
            }}>
              <Typography variant="h6" mb={2} sx={{ color: theme.palette.text.primary }}>{t('parefeux.activeParefeu')}</Typography>
              {activeParefeu ? (
                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems={isMobile ? 'flex-start' : 'center'} gap={2}>
                  <Typography sx={{ color: theme.palette.text.secondary }}>
                    {activeParefeu.titre} - {activeParefeu.etablissement?.nom || t('common.unspecified')}
                  </Typography>
                  <Button 
                    variant="outlined" 
                    color="error"
                    onClick={handleRemoveActiveParefeu}
                    fullWidth={isMobile}
                  >
                    {t('buttons.remove')}
                  </Button>
                </Box>
              ) : (
                <Typography sx={{ color: theme.palette.text.secondary }}>{t('parefeux.noActiveParefeu')}</Typography>
              )}
            </Paper>

            <TextField
              fullWidth
              variant="outlined"
              placeholder={t('parefeux.searchPlaceholder')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ 
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.secondary,
                  }
                }
              }}
            />

            <Paper elevation={3} sx={{ bgcolor: theme.palette.background.alt }}>
              <List>
                {filteredParefeux.map((parefeu) => (
                  <ListItem
                    key={parefeu._id}
                    sx={{ 
                      flexDirection: isMobile ? 'column' : 'row', 
                      gap: isMobile ? 2 : 0,
                      borderBottom: `1px solid ${theme.palette.divider}`
                    }}
                  >
                    <ListItemText
                      primary={<Typography sx={{ color: theme.palette.text.primary }}>{parefeu.titre}</Typography>}
                      secondary={
                        <Typography sx={{ color: theme.palette.text.secondary }}>
                          {t('parefeux.establishment')}: {parefeu.etablissement?.nom || t('common.unspecified')}
                        </Typography>
                      }
                    />
                    {parefeu.isActive ? (
                      <Chip 
                        label={t('parefeux.default')}
                        color="success"
                        sx={{ 
                          minWidth: '120px',
                          color: theme.palette.success.contrastText,
                          backgroundColor: theme.palette.success.main
                        }}
                      />
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() => handleSelectParefeu(parefeu._id)}
                        sx={{
                          color: theme.palette.secondary.main,
                          borderColor: theme.palette.secondary.main,
                          '&:hover': {
                            borderColor: theme.palette.secondary.secondary,
                            backgroundColor: theme.palette.secondary.third
                          }
                        }}
                        fullWidth={isMobile}
                      >
                        {t('parefeux.setDefault')}
                      </Button>
                    )}
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MesParefeux;