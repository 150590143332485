import { createSlice } from "@reduxjs/toolkit";
import i18n from './i18n';

const initialState = {
  currentLanguage: localStorage.getItem("userLanguage") || "fr",
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.currentLanguage = action.payload;
      localStorage.setItem("userLanguage", action.payload);
      i18n.changeLanguage(action.payload);
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export const selectCurrentLanguage = (state) => state.language.currentLanguage;
export default languageSlice.reducer;
