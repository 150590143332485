import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useGetAllInfoPortailClientMutation } from 'state/api';
import { usePublicAddIncidentMutation } from 'state/api';
import ContainerServicePortail from './ContainerServicePortail';
import ContainerEquipementPortail from './ContainerEquipementPortail';
import EquipementForm from './EquipementForm';
import DemandedeService from './DemandedeService';
import { useSelector } from 'react-redux';
import ValidationMessage from '../../components/ValidationMessage';
import ErrorMessage from '../../components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import LogoDisplay from '../../components/LogoDisplay';

// Utiliser la variable d'environnement REACT_APP_BASE_URL pour définir la base de l'URL
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';


const FullPageWrapper = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  gap: 20px;
`;

const PageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  height: 100%;
  background-color: ${(props) => props.couleur_de_fond || '#F5F5F5'};
  border-radius: 10px;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Title = styled('h1')`
  font-family: ${(props) => props.police_de_caractere || 'Arial, sans-serif'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
    margin-left: 20px;
  }
`;

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 80px);
  gap: 20px;

  @media (max-width: 767px) {
    gap: 10px;
  }
`;

const ContainerWrapper = styled('div')`
  flex: 1;
  width: 100%;
`;

const FullScreenContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.couleur_de_fond || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BackButton = styled('button')`
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: ${(props) => props.couleur_ecriture || '#000000'};
  font-size: 1.2em;
  cursor: pointer;
`;

const PagePortailAdmin = () => {
  const { t } = useTranslation();
  const { ID_Portail } = useParams();
  const [getAllInfoPortail, { data, error, isLoading }] = useGetAllInfoPortailClientMutation();

  const [servicesIT, setServicesIT] = useState([]);
  const [servicesSG, setServicesSG] = useState([]);
  const [equipementsIT, setEquipementsIT] = useState([]);
  const [equipementsSG, setEquipementsSG] = useState([]);
  const [equipementsParticuliersIT, setEquipementsParticuliersIT] = useState([]);
  const [equipementsParticuliersSG, setEquipementsParticuliersSG] = useState([]);
  const [personnalisation, setPersonnalisation] = useState({});
  const [logoUrl, setLogoUrl] = useState('');

  const [selectedEquipement, setSelectedEquipement] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [validationMessage, setValidationMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [publicAddIncident] = usePublicAddIncidentMutation();

  const userSession = useSelector((state) => state.userAuth);

  useEffect(() => {
    const fetchInfo = async () => {
      const result = await getAllInfoPortail({ ID_Portail });
      if (result.data) {
        const {
          services = {},
          equipements = {},
          equipementsParticuliers = {},
          personnalisation,
          etablissement,
        } = result.data;

        setServicesIT(services.IT || []);
        setServicesSG(services.SG || []);
        setEquipementsIT(equipements.IT || []);
        setEquipementsSG(equipements.SG || []);
        setEquipementsParticuliersIT(equipementsParticuliers.IT || []);
        setEquipementsParticuliersSG(equipementsParticuliers.SG || []);
        setPersonnalisation(personnalisation || {});
        setLogoUrl(personnalisation.Logo || '');
      }
    };

    fetchInfo();
  }, [ID_Portail, getAllInfoPortail]);

  const handleEquipementClick = (equipement) => {
    setSelectedEquipement(equipement);
  };

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };

  const handleBack = () => {
    setSelectedEquipement(null);
    setSelectedService(null);
  };

  const handleEquipementFormSubmit = async (formData) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('type', selectedEquipement.ID_Ep_IT || selectedEquipement.ID_Equipement_IT ? 'IT' : 'SG');
      formDataToSend.append('ID_Etablissement', data?.etablissement?.ID_Etablissement);
      formDataToSend.append('ID_Portail', ID_Portail);
      formDataToSend.append('ID_Ep_IT', selectedEquipement.ID_Ep_IT || '');
      formDataToSend.append('ID_Ep_SG', selectedEquipement.ID_Ep_SG || '');
      formDataToSend.append('ID_Equipement_IT', selectedEquipement.ID_Equipement_IT || '');
      formDataToSend.append('ID_Equipement_SG', selectedEquipement.ID_Equipement_SG || '');
      formDataToSend.append('Description', formData.Description);
      formDataToSend.append('Niveau_urgence_intervention', formData.Niveau_urgence_intervention);
      formDataToSend.append('Email_signaleur', formData.Email_signaleur);
      formDataToSend.append('Nombre_de_particule_impacte', formData.Nombre_de_particule_impacte);
      formDataToSend.append('ID_Utilisateur', userSession.user.ID_Utilisateur);
      if (formData.Attachment) {
        formDataToSend.append('Attachment', formData.Attachment);
      }

      await publicAddIncident(formDataToSend);
      setValidationMessage('Incident soumis avec succès');
      handleBack();
    } catch (error) {
      setErrorMessage("Erreur lors de la soumission de l'incident");
    }
  };

  const handleServiceFormSubmit = async (formData) => {
    try {
      const formDataToSend = new FormData();
      // Déterminer le type de service (IT ou SG)
      formDataToSend.append('type', selectedService.ID_Service_IT ? 'IT' : 'SG');
      formDataToSend.append('ID_Etablissement', data?.etablissement?.ID_Etablissement);
      formDataToSend.append('ID_Service_IT', selectedService.ID_Service_IT || '');
      formDataToSend.append('ID_Service_SG', selectedService.ID_Service_SG || '');
      formDataToSend.append('ID_Portail', ID_Portail);
  
      // Ajouter les champs standards
      formDataToSend.append('Besoin', formData.Besoin);
      formDataToSend.append('Precision_Besoin', formData.Precision_Besoin);
      formDataToSend.append('Description', formData.Description);
      
  
      // Gérer les champs de date en fonction de `Date_Requise_Type`
      switch (selectedService.Date_Requise) {
        case 'Date_unique':
          formDataToSend.append('Date_Requise_Type', selectedService.Date_Requise);
          formDataToSend.append('Date_unique', formData.Date_unique || '');
          break;
        case 'Intervalle_de_date':
          formDataToSend.append('Date_Requise_Type', selectedService.Date_Requise);
          formDataToSend.append('Intervalle_de_date[Date_debut]', formData.Intervalle_de_date?.Date_debut || '');
          formDataToSend.append('Intervalle_de_date[Date_fin]', formData.Intervalle_de_date?.Date_fin || '');
          break;
        case 'Date_unique_avec_heure_unique':
          formDataToSend.append('Date_Requise_Type', selectedService.Date_Requise);
          formDataToSend.append('Date_unique_avec_heure_unique[Date]', formData.Date_unique || '');
          formDataToSend.append('Date_unique_avec_heure_unique[Heure]', formData.Heure_Debut || '');
          break;
        case 'Data_unique_avec_intervalle_dheure':
          formDataToSend.append('Date_Requise_Type', selectedService.Date_Requise);
          formDataToSend.append('Data_unique_avec_intervalle_dheure[Date]', formData.Date_unique || '');
          formDataToSend.append('Data_unique_avec_intervalle_dheure[Heure_debut]', formData.Heure_Debut || '');
          formDataToSend.append('Data_unique_avec_intervalle_dheure[Heure_fin]', formData.Heure_Fin || '');
          break;
        case 'Intervalle_de_date_avec_heure_unique':
          formDataToSend.append('Date_Requise_Type', selectedService.Date_Requise);
          formDataToSend.append('Intervalle_de_date_avec_heure_unique[Date_debut]', formData.Intervalle_de_date?.Date_debut || '');
          formDataToSend.append('Intervalle_de_date_avec_heure_unique[Date_fin]', formData.Intervalle_de_date?.Date_fin || '');
          formDataToSend.append('Intervalle_de_date_avec_heure_unique[Heure]', formData.Heure_Debut || '');
          break;
        case 'Intervalle_de_date_avec_intervalle_dheure':
          formDataToSend.append('Date_Requise_Type', selectedService.Date_Requise);
          formDataToSend.append('Intervalle_de_date_avec_intervalle_dheure[Date_debut]', formData.Intervalle_de_date?.Date_debut || '');
          formDataToSend.append('Intervalle_de_date_avec_intervalle_dheure[Date_fin]', formData.Intervalle_de_date?.Date_fin || '');
          formDataToSend.append('Intervalle_de_date_avec_intervalle_dheure[Heure_debut]', formData.Heure_Debut || '');
          formDataToSend.append('Intervalle_de_date_avec_intervalle_dheure[Heure_fin]', formData.Heure_Fin || '');
          break;
        default:
          formDataToSend.append('Date_Requise_Type', selectedService.Date_Requise);
          break;
      }
  
      // Ajouter les informations de contact et de l'utilisateur
      formDataToSend.append('Email_signaleur', formData.Email_signaleur);
      formDataToSend.append('Email_cc', formData.Email_cc);
      formDataToSend.append('ID_Utilisateur', userSession.user.ID_Utilisateur);
  
      // Ajouter un fichier d'attachement si disponible
      if (formData.Attachment) {
        formDataToSend.append('Attachment', formData.Attachment);
      }
  
      // Envoyer les données au backend
      await publicAddIncident(formDataToSend);
      setValidationMessage('Demande de service soumise avec succès');
      handleBack();
    } catch (error) {
      console.error("Erreur lors de la soumission de la demande de service:", error);
      setErrorMessage("Erreur lors de la soumission de la demande de service");
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data</div>;

  return (
    <FullPageWrapper>
      {selectedEquipement ? (
        <FullScreenContainer couleur_de_fond={personnalisation?.couleur_de_fond} couleur_ecriture={personnalisation?.couleur_ecriture}>
          <BackButton onClick={handleBack} couleur_ecriture={personnalisation?.couleur_ecriture}>
            {t('PagePortailAdmin.backButton')}
          </BackButton>
          <EquipementForm
            onClose={handleBack}
            couleur_de_fond={personnalisation?.couleur_de_fond}
            couleur_ecriture={personnalisation?.couleur_ecriture}
            couleur_bouton={personnalisation?.couleur_bouton}
            couleur_bouton_ecriture={personnalisation?.couleur_bouton_ecriture}
            police_de_caractere={personnalisation?.police_de_caractere}
            maxParticules={selectedEquipement.Nombre_particules}
            logoUrl={logoUrl}
            onFormSubmit={handleEquipementFormSubmit}
          />
        </FullScreenContainer>
      ) : selectedService ? (
        <FullScreenContainer couleur_de_fond={personnalisation?.couleur_de_fond} couleur_ecriture={personnalisation?.couleur_ecriture}>
          <BackButton onClick={handleBack} couleur_ecriture={personnalisation?.couleur_ecriture}>
            {t('PagePortailAdmin.backButton')}
          </BackButton>
          <DemandedeService
            onClose={handleBack}
            selectedService={selectedService}
            ID_Etablissement={data?.etablissement?.ID_Etablissement}
            setValidationMessage={setValidationMessage}
            couleur_de_fond={personnalisation?.couleur_de_fond}
            couleur_ecriture={personnalisation?.couleur_ecriture}
            couleur_bouton={personnalisation?.couleur_bouton}
            couleur_bouton_ecriture={personnalisation?.couleur_bouton_ecriture}
            couleur_bordure={personnalisation?.couleur_bordure}
            police_de_caractere={personnalisation?.police_de_caractere}
            logoUrl={logoUrl}
            onFormSubmit={handleServiceFormSubmit}
          />
        </FullScreenContainer>
      ) : (
        <PageContainer
          couleur_de_fond={personnalisation?.couleur_de_fond}
          style={{ fontFamily: personnalisation?.URL_police_de_caractere }}
        >
          <Header>
            <LogoDisplay
              logo={logoUrl}
              baseUrl={BASE_URL}
              maxHeight="100px"
            />
            <Title
              couleur_ecriture={personnalisation?.couleur_ecriture}
              police_de_caractere={personnalisation?.police_de_caractere}
            >
              {t('PagePortailAdmin.title')}
            </Title>
          </Header>
          <ContentContainer>
            <ContainerWrapper>
              <ContainerServicePortail
                services={[...servicesIT, ...servicesSG]}
                couleur_de_fond={personnalisation?.couleur_de_case}
                couleur_ecriture={personnalisation?.couleur_ecriture}
                couleur_dombre_de_case={personnalisation?.couleur_dombre_de_case}
                police_de_caractere={personnalisation?.police_de_caractere}
                onServiceClick={handleServiceClick}
              />
            </ContainerWrapper>
            <ContainerWrapper>
              <ContainerEquipementPortail
                equipements={[...equipementsParticuliersIT, ...equipementsParticuliersSG, ...equipementsIT, ...equipementsSG]}
                couleur_de_fond={personnalisation?.couleur_de_case}
                couleur_ecriture={personnalisation?.couleur_ecriture}
                couleur_dombre_de_case={personnalisation?.couleur_dombre_de_case}
                police_de_caractere={personnalisation?.police_de_caractere}
                onEquipementClick={handleEquipementClick}
              />
            </ContainerWrapper>
          </ContentContainer>
        </PageContainer>
      )}
      {/* Affichage des messages de validation et d'erreur */}
      {validationMessage && (
        <ValidationMessage message={t('PagePortailAdmin.validationMessage')} setValidation={setValidationMessage} />
      )}
      {errorMessage && (
        <ErrorMessage message={t('PagePortailAdmin.errorMessage')} setError={setErrorMessage} />
      )}
    </FullPageWrapper>
  );
};

export default PagePortailAdmin;