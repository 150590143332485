import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid } from "@mui/material";
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import LogoDisplay from '../../components/LogoDisplay';

const StyledMobilePreview = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 534px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  overflow: hidden;
`;

const MainContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;
  height: 100%;
  gap: 24px;
`;

const SignalementCard = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  background-color: ${(props) => props.couleur_de_case || '#FFFFFF'};
  color: ${(props) => props.couleur_ecriture || '#000000'};
  box-shadow: 5px 5px 10px ${(props) => props.couleur_dombre_de_case || 'rgba(0, 0, 0, 0.1)'};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;
  height: 190px;
  width: 80%;

  &:hover {
    transform: scale(1.05);
  }
`;

const MobilePreview = ({
  couleur_de_fond: couleurDeFondInitial,
  couleur_de_case: couleurDeCaseInitial,
  couleur_de_case_active: couleurDeCaseActiveInitial,
  couleur_decriture: couleurDecritureInitial,
  couleur_dombre_de_case: couleurDombreDeCaseInitial,
  couleur_bouton: couleurBoutonInitial,
  couleur_bouton_ecriture: couleurBoutonEcritureInitial,
  couleur_ombre_bouton: couleurOmbreBoutonInitial,
  police_de_caractere: policeDeCaractereInitial,
  URL_police_de_caractere: URLPoliceDeCaractereInitial,
  selectedFile: selectedFileInitial,
  Logo,
  t,
  BASE_URL,
  onUpdateCouleurDeFond,
  onUpdateCouleurDeCase,
  onUpdateCouleurDeCaseActive,
  onUpdateCouleurDecriture,
  onUpdateCouleurDombreDeCase,
  onUpdateCouleurBouton,
  onUpdateCouleurBoutonEcriture,
  onUpdateCouleurOmbreBouton,
  onUpdatePoliceDeCaractere,
  onUpdateURLPoliceDeCaractere,
  onUpdateSelectedFile,
  tempLogoFile,
  setTempLogoFile
}) => {
  // États locaux pour les modifications temporaires
  const [couleurDeFond, setCouleurDeFond] = useState(couleurDeFondInitial);
  const [couleurDeCase, setCouleurDeCase] = useState(couleurDeCaseInitial);
  const [couleurDeCaseActive, setCouleurDeCaseActive] = useState(couleurDeCaseActiveInitial);
  const [couleurDecriture, setCouleurDecriture] = useState(couleurDecritureInitial);
  const [couleurDombreDeCase, setCouleurDombreDeCase] = useState(couleurDombreDeCaseInitial);
  const [couleurBouton, setCouleurBouton] = useState(couleurBoutonInitial);
  const [couleurBoutonEcriture, setCouleurBoutonEcriture] = useState(couleurBoutonEcritureInitial);
  const [couleurOmbreBouton, setCouleurOmbreBouton] = useState(couleurOmbreBoutonInitial);
  const [policeDeCaractere, setPoliceDeCaractere] = useState(policeDeCaractereInitial);
  const [URLPoliceDeCaractere, setURLPoliceDeCaractere] = useState(URLPoliceDeCaractereInitial);
  const [selectedFile, setSelectedFile] = useState(selectedFileInitial);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setTempLogoFile(file);
      onUpdateSelectedFile?.(file);
    }
  };

  const handleColorChange = (setter, updater) => (e) => {
    const newValue = e.target.value;
    setter(newValue);
    updater?.(newValue);
  };

  return (
    <Box>
      <Typography variant="h4" align="center" sx={{ my: 4 }}>
        {t('Personnalisation.mobilePreviewTitle')}
      </Typography>
      
      <Box sx={{ display: 'flex', gap: 4, alignItems: 'center', minHeight: 'calc(100vh - 200px)' }}>
        <Box sx={{ width: '60%' }}>
          <StyledMobilePreview>
            <Box style={{ backgroundColor: couleurDeFond, height: '100%', display: 'flex', alignItems: 'center' }}>
              <MainContainer>
                <LogoDisplay 
                  logo={Logo}
                  baseUrl={BASE_URL}
                  maxHeight="120px"
                  isTemporary={!!tempLogoFile}
                  temporaryImage={tempLogoFile}
                />
                <SignalementCard
                  couleur_de_case={couleurDeCase}
                  couleur_ecriture={couleurDecriture}
                  couleur_dombre_de_case={couleurDombreDeCase}
                >
                  {t('Personnalisation.sampleCard')}
                </SignalementCard>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: couleurBouton,
                    color: couleurBoutonEcriture,
                    boxShadow: `0px 4px 8px ${couleurOmbreBouton}`,
                    fontFamily: `'${policeDeCaractere}', sans-serif`,
                    margin: '16px 0',
                  }}
                >
                  {t('Personnalisation.sampleButton')}
                </Button>
              </MainContainer>
            </Box>
          </StyledMobilePreview>
        </Box>

        <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {t('Personnalisation.uploadLogoLabel') || 'Déposez votre logo ici :'}
          </Typography>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label={t('Personnalisation.backgroundColorLabel')}
            type="color"
            fullWidth
            value={couleurDeFond}
            onChange={handleColorChange(setCouleurDeFond, onUpdateCouleurDeFond)}
            InputProps={{ style: { height: '56px' } }}
          />
          <TextField
            label={t('Personnalisation.cardColorLabel')}
            type="color"
            fullWidth
            value={couleurDeCase}
            onChange={handleColorChange(setCouleurDeCase, onUpdateCouleurDeCase)}
            InputProps={{ style: { height: '56px' } }}
          />
          <TextField
            label={t('Personnalisation.activeCardColorLabel')}
            type="color"
            fullWidth
            value={couleurDeCaseActive}
            onChange={handleColorChange(setCouleurDeCaseActive, onUpdateCouleurDeCaseActive)}
            InputProps={{ style: { height: '56px' } }}
          />
          <TextField
            label={t('Personnalisation.textColorLabel')}
            type="color"
            fullWidth
            value={couleurDecriture}
            onChange={handleColorChange(setCouleurDecriture, onUpdateCouleurDecriture)}
            InputProps={{ style: { height: '56px' } }}
          />
          <TextField
            label={t('Personnalisation.cardShadowColorLabel')}
            type="color"
            fullWidth
            value={couleurDombreDeCase}
            onChange={handleColorChange(setCouleurDombreDeCase, onUpdateCouleurDombreDeCase)}
            InputProps={{ style: { height: '56px' } }}
          />
          <TextField
            label={t('Personnalisation.buttonColorLabel')}
            type="color"
            fullWidth
            value={couleurBouton}
            onChange={handleColorChange(setCouleurBouton, onUpdateCouleurBouton)}
            InputProps={{ style: { height: '56px' } }}
          />
          <TextField
            label={t('Personnalisation.buttonTextColorLabel')}
            type="color"
            fullWidth
            value={couleurBoutonEcriture}
            onChange={handleColorChange(setCouleurBoutonEcriture, onUpdateCouleurBoutonEcriture)}
            InputProps={{ style: { height: '56px' } }}
          />
          <TextField
            label={t('Personnalisation.buttonShadowColorLabel')}
            type="color"
            fullWidth
            value={couleurOmbreBouton}
            onChange={handleColorChange(setCouleurOmbreBouton, onUpdateCouleurOmbreBouton)}
            InputProps={{ style: { height: '56px' } }}
          />
          <TextField
            label={t('Personnalisation.fontFamilyLabel')}
            fullWidth
            value={policeDeCaractere}
            onChange={handleColorChange(setPoliceDeCaractere, onUpdatePoliceDeCaractere)}
            InputProps={{ style: { height: '56px' } }}
          />
          <TextField
            label={t('Personnalisation.fontUrlLabel')}
            fullWidth
            value={URLPoliceDeCaractere}
            onChange={handleColorChange(setURLPoliceDeCaractere, onUpdateURLPoliceDeCaractere)}
            InputProps={{ style: { height: '56px' } }}
          />
        </Box>
      </Box>
    </Box>
  );
};

MobilePreview.propTypes = {
  couleur_de_fond: PropTypes.string,
  couleur_de_case: PropTypes.string,
  couleur_de_case_active: PropTypes.string,
  couleur_decriture: PropTypes.string,
  couleur_dombre_de_case: PropTypes.string,
  couleur_bouton: PropTypes.string,
  couleur_bouton_ecriture: PropTypes.string,
  couleur_ombre_bouton: PropTypes.string,
  police_de_caractere: PropTypes.string,
  URL_police_de_caractere: PropTypes.string,
  selectedFile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  Logo: PropTypes.string,
  t: PropTypes.func.isRequired,
  BASE_URL: PropTypes.string,
  onUpdateCouleurDeFond: PropTypes.func,
  onUpdateCouleurDeCase: PropTypes.func,
  onUpdateCouleurDeCaseActive: PropTypes.func,
  onUpdateCouleurDecriture: PropTypes.func,
  onUpdateCouleurDombreDeCase: PropTypes.func,
  onUpdateCouleurBouton: PropTypes.func,
  onUpdateCouleurBoutonEcriture: PropTypes.func,
  onUpdateCouleurOmbreBouton: PropTypes.func,
  onUpdatePoliceDeCaractere: PropTypes.func,
  onUpdateURLPoliceDeCaractere: PropTypes.func,
  onUpdateSelectedFile: PropTypes.func,
  tempLogoFile: PropTypes.object,
  setTempLogoFile: PropTypes.func
};

export default MobilePreview;