import { useState, useEffect } from 'react';

export const useLanguage = () => {
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    return localStorage.getItem('userLanguage') || 'fr';
  });

  useEffect(() => {
    localStorage.setItem('userLanguage', currentLanguage);
  }, [currentLanguage]);

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang);
  };

  return { currentLanguage, changeLanguage };
}; 