import React, { useState, useEffect } from "react";
import { Box, Grid, Button, Divider } from "@mui/material";
import { useGetPersonnalisationQuery, useSetPersonnalisationMutation, useDeleteBannerMutation } from "state/api";
import Header from "components/Header";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import DesktopPreview from './DesktopPreview';
import MobilePreview from './MobilePreview';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';

const Personnalisation = () => {
  const { t } = useTranslation();
  const ID_Etablissement = useSelector((state) => state.adminAuth.ID_Etablissement);
  const { data: personnalisationData } = useGetPersonnalisationQuery(ID_Etablissement);
  const [setPersonnalisation] = useSetPersonnalisationMutation();
  const [deleteBanner] = useDeleteBannerMutation();

  // États pour la gestion des fichiers
  const [selectedFile, setSelectedFile] = useState(null);
  const [banniereFile, setBanniereFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [previewLogo, setPreviewLogo] = useState(null);
  const [tempLogoFile, setTempLogoFile] = useState(null);

  // États pour les couleurs et la police
  const [couleur_de_fond, setCouleur_de_fond] = useState('#FFFFFF');
  const [couleur_de_fond_haut, setCouleur_de_fond_haut] = useState('#FFFFFF');
  const [couleur_de_case, setCouleur_de_case] = useState('#FFFFFF');
  const [couleur_de_case_active, setCouleur_de_case_active] = useState('#FFFFFF');
  const [couleur_dombre_de_case, setCouleur_dombre_de_case] = useState('rgba(0, 0, 0, 0.1)');
  const [couleur_decriture, setCouleur_decriture] = useState('#000000');
  const [couleur_bouton, setCouleur_bouton] = useState('#1976D2');
  const [couleur_bouton_ecriture, setCouleur_bouton_ecriture] = useState('#FFFFFF');
  const [couleur_ombre_bouton, setCouleur_ombre_bouton] = useState('rgba(0, 0, 0, 0.2)');
  const [police_de_caractere, setPolice_de_caractere] = useState('Arial');
  const [URL_police_de_caractere, setURL_police_de_caractere] = useState('');
  const [Logo, setLogo] = useState(null);
  const [Banniere, setBanniere] = useState(null);
  const [couleur_ecriture_banniere, setCouleur_ecriture_banniere] = useState('#FFFFFF');

  // Effet pour charger les données initiales
  useEffect(() => {
    if (personnalisationData) {
      setCouleur_de_fond(personnalisationData.couleur_de_fond || '#FFFFFF');
      setCouleur_de_fond_haut(personnalisationData.couleur_de_fond_haut || '#FFFFFF');
      setCouleur_de_case(personnalisationData.couleur_de_case || '#FFFFFF');
      setCouleur_de_case_active(personnalisationData.couleur_de_case_active || '#FFFFFF');
      setCouleur_dombre_de_case(personnalisationData.couleur_dombre_de_case || 'rgba(0, 0, 0, 0.1)');
      setCouleur_decriture(personnalisationData.couleur_ecriture || '#000000');
      setCouleur_bouton(personnalisationData.couleur_bouton || '#1976D2');
      setCouleur_bouton_ecriture(personnalisationData.couleur_bouton_ecriture || '#FFFFFF');
      setCouleur_ombre_bouton(personnalisationData.couleur_ombre_bouton || 'rgba(0, 0, 0, 0.2)');
      setPolice_de_caractere(personnalisationData.police_de_caractere || 'Arial');
      setURL_police_de_caractere(personnalisationData.URL_police_de_caractere || '');
      setLogo(personnalisationData.Logo || null);
      setBanniere(personnalisationData.Banniere || null);
      setCouleur_ecriture_banniere(personnalisationData.couleur_ecriture_banniere || '#FFFFFF');
    }
  }, [personnalisationData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmittingForm(true);

    try {
      const personnalisationData = {
        couleur_de_fond,
        couleur_de_fond_haut,
        couleur_de_case,
        couleur_de_case_active,
        couleur_dombre_de_case,
        couleur_ecriture: couleur_decriture,
        couleur_bouton,
        couleur_bouton_ecriture,
        couleur_ombre_bouton,
        police_de_caractere,
        URL_police_de_caractere,
        ID_Etablissement,
        logo: selectedFile,
        banniere: banniereFile,
        couleur_ecriture_banniere,
      };

      await setPersonnalisation(personnalisationData).unwrap();
      setIsSubmittingForm(false);

    } catch (err) {
      console.error('Erreur lors de la mise à jour:', err);
      setIsSubmittingForm(false);
    }
  };

  const handleUpdateSelectedFile = (file) => {
    setSelectedFile(file);
    setTempLogoFile(file);
    if (file) {
      const tempUrl = URL.createObjectURL(file);
      setPreviewLogo(tempUrl);
    } else {
      setPreviewLogo(null);
    }
  };

  const handleDeleteBanner = async () => {
    try {
      await deleteBanner(ID_Etablissement).unwrap();
      setBanniereFile(null);
      // Mise à jour locale de l'état
      setPersonnalisation({
        ...personnalisationData,
        banniere: null,
        ID_Etablissement,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de la bannière:', error);
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t('Personnalisation.headerTitle')} subtitle={t('Personnalisation.headerSubtitle')} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <DesktopPreview
              banniere={Banniere}
              banniereFile={banniereFile}
              personnalisationData={personnalisationData}
              couleur_de_fond_haut={couleur_de_fond_haut}
              couleur_decriture={couleur_decriture}
              police_de_caractere={police_de_caractere}
              selectedFile={selectedFile}
              setCouleur_de_fond_haut={setCouleur_de_fond_haut}
              setBanniereFile={setBanniereFile}
              t={t}
              BASE_URL={BASE_URL}
              Logo={Logo}
              previewLogo={previewLogo}
              tempLogoFile={tempLogoFile}
              couleur_ecriture_banniere={couleur_ecriture_banniere}
              setCouleur_ecriture_banniere={setCouleur_ecriture_banniere}
              onDeleteBanner={handleDeleteBanner}
            />
          </Grid>
          
          <Grid item xs={12}>
            <MobilePreview
              couleur_de_fond={couleur_de_fond}
              couleur_de_case={couleur_de_case}
              couleur_de_case_active={couleur_de_case_active}
              couleur_decriture={couleur_decriture}
              couleur_dombre_de_case={couleur_dombre_de_case}
              couleur_bouton={couleur_bouton}
              couleur_bouton_ecriture={couleur_bouton_ecriture}
              couleur_ombre_bouton={couleur_ombre_bouton}
              police_de_caractere={police_de_caractere}
              URL_police_de_caractere={URL_police_de_caractere}
              selectedFile={selectedFile}
              Logo={Logo}
              t={t}
              BASE_URL={BASE_URL}
              onUpdateCouleurDeFond={setCouleur_de_fond}
              onUpdateCouleurDeCase={setCouleur_de_case}
              onUpdateCouleurDeCaseActive={setCouleur_de_case_active}
              onUpdateCouleurDecriture={setCouleur_decriture}
              onUpdateCouleurDombreDeCase={setCouleur_dombre_de_case}
              onUpdateCouleurBouton={setCouleur_bouton}
              onUpdateCouleurBoutonEcriture={setCouleur_bouton_ecriture}
              onUpdateCouleurOmbreBouton={setCouleur_ombre_bouton}
              onUpdatePoliceDeCaractere={setPolice_de_caractere}
              onUpdateURLPoliceDeCaractere={setURL_police_de_caractere}
              onUpdateSelectedFile={handleUpdateSelectedFile}
              tempLogoFile={tempLogoFile}
              setTempLogoFile={setTempLogoFile}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 4, borderWidth: 2 }} />
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              disabled={isSubmittingForm}
              fullWidth
              size="large"
              sx={{ 
                py: 2, 
                mb: 4,
                color: (theme) => theme.palette.mode === 'light' ? '#000000' : '#ffffff'
              }}
            >
              {t('Personnalisation.submitButtonText')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Personnalisation;
